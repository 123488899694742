/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { PopoverButton } from 'components';
import { SortDownIcon, SortUpIcon } from 'icons';
import type { PlaylistOrder } from 'types';

type PlaylistOrderButtonProps = {
	order: PlaylistOrder;
	onChange: (order: PlaylistOrder) => void;
};

const PlaylistOrderButton: FC<PlaylistOrderButtonProps> = ({
	order,
	onChange,
}) => {
	const newestFirst = 'desc' === order;
	const Icon = newestFirst ? SortDownIcon : SortUpIcon;
	const orderLabel = newestFirst ? 'oldest' : 'newest';

	return (
		<PopoverButton
			onClick={() => onChange(newestFirst ? 'asc' : 'desc')}
			className='fbx-order-button'
			buttonStyle="secondary"
			icon={<Icon />}
			popoverClassName='fbx-fusebox-playlist-order-popover'
			popoverContent={`Sort ${orderLabel} first`}
			popoverOpenEvent="hover"
			popoverPlacement="top"
		/>
	);
};

export default PlaylistOrderButton;
