/**
 * Internal dependencies
 */
import { buildApiUrl, apiCall } from 'utils';

/**
 * Loads episode description.
 *
 * @param  showHash  Show hash.
 * @param  episodeNo Episode number.
 * @return           Episode description.
 */
export const loadDescription = async (
	showHash: string,
	episodeNo: number
): Promise<string> => {
	const result = await apiCall<{ description: string }>(
		buildApiUrl(`shows/${showHash}/episodes/${episodeNo}/description`)
	);

	if (result && result.description) {
		return result.description;
	}

	return 'Cannot load description.';
};
