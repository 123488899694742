/**
 * External dependencies
 */
import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { Button } from 'components';
import { TranscriptIcon } from 'icons';

type TranscriptButtonProps = {
	active?: boolean;
	onClick: () => void;
};

const TranscriptButton: FC<TranscriptButtonProps> = ({
	active = false,
	onClick,
}) => (
	<Button
		onClick={onClick}
		className={classnames('fbx-transcript-button', { 'fbx-is-active': active })}
		buttonStyle="secondary"
		icon={<TranscriptIcon />}
	/>
);

export default TranscriptButton;
