/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { Button } from 'components';
import { ExternalLinkIcon } from 'icons';
import { openWindow } from 'utils';
import { useConfig, usePlayerState } from 'context';

const PopupButton: FC = () => {
	const config = useConfig();
	const [state, setState] = usePlayerState();

	if (config!.isStandalone) {
		return null;
	}

	const onClose = () => {
		// @ts-ignore
		setState({
			disabled: false,
		});
	};

	const onClick = () => {
		// @ts-ignore
		setState({
			disabled: true,
		});

		openWindow(config!, state, onClose);
	};

	return (
		<Button
			onClick={onClick}
			className='fbx-popup-button'
			buttonStyle="secondary"
			icon={<ExternalLinkIcon />}
		/>
	);
};

export default PopupButton;
