/**
 * External dependencies
 */
import React, { FC, Children, isValidElement, cloneElement } from 'react';
import classnames from 'classnames';
import { compact } from 'lodash';

/**
 * Internal dependencies
 */
import { withConfig, withConfigProps } from 'HOC/withConfig';
import { withPlayerState, withPlayerStateProps } from 'HOC/withPlayerState';
import ArchiveButton from 'components/ArchiveButton';
import Button from 'components/Button';
import DownloadButton from 'components/DownloadButton';
import MenuButton from 'components/MenuButton';
import ShareButton from 'components/ShareButton';
import SubscribeButton from 'components/SubscribeButton';

const Buttons: FC<withConfigProps & withPlayerStateProps> = ({
	config,
	playerState: { layout, playerWidth },
}) => {
	const { buttons, buttonConfig, sticky } = config;

	const hasButton = (type: string) => buttons.includes(type as any);

	const cta = hasButton('cta') && buttonConfig.cta;
	const subscribeLinks = hasButton('subscribe') && buttonConfig.subscribe;

	const ctaBefore = !sticky && ('narrow' !== layout || 400 > playerWidth!);
	const shareButtonBefore = !cta && 'narrow' === layout;
	const subscribeButtonBefore = !shareButtonBefore && !cta;

	const hasMenu = sticky && ['wide', 'medium'].includes(layout!);
	const ctaInMenu = hasMenu && 'medium' === layout;
	const subscribeButtonInMenu = hasMenu && (cta || 'medium' === layout);

	const className = classnames('fbx-buttons', {
		'fbx-has-cta': !!cta,
	});

	const ctaButton = cta ? (
		<Button className='fbx-cta-button' url={cta.url} target={cta.target}>
			{cta.label}
		</Button>
	) : null;

	const subscribeButton =
		subscribeLinks && Object.keys(subscribeLinks).length ? (
			<SubscribeButton
				buttonStyle={cta ? 'secondary' : 'primary'}
				popover={true}
				links={subscribeLinks}
			/>
		) : null;

	const shareButton = hasButton('share') ? (
		<ShareButton popover={true} />
	) : null;

	const downloadButton = hasButton('download') ? (
		<DownloadButton hasLabel={hasMenu} />
	) : null;

	const menuItems = compact(
		Children.map(
			[
				ctaInMenu && ctaButton,
				subscribeButtonInMenu && subscribeButton,
				shareButton,
				downloadButton,
			],
			(element) => {
				if (isValidElement(element)) {
					return cloneElement(element, {
						...(element as JSX.Element).props,
						popover: false,
					});
				}
			}
		)
	);

	return (
		<div className={className}>
			{ctaButton && ctaBefore && (
				<div className='fbx-buttons-row'>{ctaButton}</div>
			)}
			{subscribeButtonBefore && !sticky && (
				<div className='fbx-buttons-row'>{subscribeButton}</div>
			)}
			<div className='fbx-buttons-row'>
				{sticky && <ArchiveButton />}
				{hasMenu && menuItems.length > 1 ? (
					<MenuButton>{menuItems}</MenuButton>
				) : (
					<>
						{shareButtonBefore && shareButton}
						{(shareButtonBefore || ctaButton) && subscribeButton}
						{!shareButtonBefore && shareButton}
						{downloadButton}
					</>
				)}
				{!ctaBefore && !ctaInMenu && ctaButton}
				{subscribeButtonBefore &&
					sticky &&
					!subscribeButtonInMenu &&
					subscribeButton}
				{!sticky && <ArchiveButton />}
			</div>
		</div>
	);
};

export default withPlayerState(withConfig(Buttons));
