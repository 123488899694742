/**
 * External dependencies
 */
import { MutableRefObject, Ref, useEffect, useRef } from 'react';

const useCombinedRefs = <T = undefined>(...refs: Array<Ref<T>>) => {
	const targetRef = useRef<T>(null);

	useEffect(() => {
		refs.forEach((ref) => {
			if (!ref) {
				return;
			}

			if (typeof ref === 'function') {
				return ref(targetRef.current);
			}

			(ref as MutableRefObject<T | null>).current = targetRef.current;
		});
	}, [refs]);

	return targetRef;
};

export default useCombinedRefs;
