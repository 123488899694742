/**
 * External dependencies
 */
import React, { FC, useState, useEffect } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { useConfig } from 'context/config';
import { withPlayerState, withPlayerStateProps } from 'HOC/withPlayerState';
import PlaylistItems from 'components/PlaylistItems';
import PlaylistNavigation, { Tab } from 'components/PlaylistNavigation';
import PlaylistOrderButton from 'components/PlaylistOrderButton';
import PopupButton from 'components/PopupButton';
import SearchForm from 'components/SearchForm';
import Transcript from 'components/Transcript';
import TranscriptButton from 'components/TranscriptButton';
import type { Playlist as PlaylistConfig } from 'interfaces';
import type { PlaylistOrder, PlaylistFeature } from 'types';

type PlaylistProps = {
	config?: PlaylistConfig;
	allowTwoColumns?: boolean;
	layoutRef?: HTMLDivElement;
	transcript?: string | null;
} & withPlayerStateProps;

const Playlist: FC<PlaylistProps> = ({
	allowTwoColumns,
	config: playlistConfig,
	layoutRef,
	playerState: { playerWidth, playerHeight },
	transcript,
}) => {
	const color = playlistConfig?.color || 'light';
	const displayFeaturedEpisodes = !!playlistConfig?.displayFeaturedEpisodes;
	const initialOrder = playlistConfig?.order;
	const initialTab = displayFeaturedEpisodes
		? 'featured'
		: !!playlistConfig
		? 'all'
		: 'transcript';

	const [currentTab, setCurrentTab] = useState<Tab>(initialTab);
	const [episodesSearch, setEpisodesSearch] = useState<string>('');
	const [hasFloatingSearch, setHasFloatingSearch] = useState<boolean>(false);
	const [height, setHeight] = useState<number>(0);
	const [order, setOrder] = useState<PlaylistOrder | undefined>(initialOrder);
	const [transcriptSearch, setTranscriptSearch] = useState<string>('');
	const config = useConfig();

	const { isStandalone } = config!;

	useEffect(() => {
		if (isStandalone && layoutRef && playerHeight) {
			const newHeight = playerHeight - layoutRef.offsetHeight;
			setHeight(newHeight);
		}
	}, [playerWidth, playerHeight, isStandalone, layoutRef]);

	useEffect(() => {
		if (initialOrder !== order) {
			setOrder(initialOrder);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [initialOrder]);

	const layout = playerWidth
		? playerWidth < 600
			? 'small'
			: playerWidth < 700
			? 'medium'
			: 'large'
		: false;

	const isXSmall = playerWidth && playerWidth < 460;
	const isSmall = 'small' === layout;
	const isMedium = 'medium' === layout;

	const hasAllTabs =
		!!playlistConfig && !!displayFeaturedEpisodes && !!transcript;
	const isTranscriptFloating = !!transcript && !!isXSmall && !!playlistConfig;
	const isSearchFormFloating = isSmall || (isMedium && hasAllTabs);

	const className = classnames('fbx-playlist', `fbx-has-${color}-theme`, {
		[`fbx-is-${layout}`]: layout,
	});

	const headerClassName = classnames('fbx-playlist-header', {
		'fbx-has-floating-search': hasFloatingSearch,
	});

	const hasFeature = (feature: PlaylistFeature) =>
		playlistConfig && playlistConfig.features.includes(feature);

	const style = height
		? {
				height: `${height}px`,
		  }
		: undefined;

	const isTranscriptTab = currentTab === 'transcript';
	const search = isTranscriptTab ? transcriptSearch : episodesSearch;
	const setSearch = isTranscriptTab ? setTranscriptSearch : setEpisodesSearch;
	const searchPlaceholder = isTranscriptTab
		? 'Search transcript...'
		: 'Search episodes...';

	return (
		<div className={className} style={style}>
			<div className={headerClassName}>
				<PlaylistNavigation
					allEpisodes={!!playlistConfig}
					currentTab={currentTab}
					featuredEpisodes={displayFeaturedEpisodes}
					onChange={setCurrentTab}
					transcript={!!transcript && !isTranscriptFloating}
				/>
				<div className='fbx-playlist-toolbar'>
					{isTranscriptFloating && (
						<TranscriptButton
							active={currentTab === 'transcript'}
							onClick={() => setCurrentTab('transcript')}
						/>
					)}
					{(hasFeature('searchbox') || !playlistConfig) && (
						<SearchForm
							isFloating={isSearchFormFloating}
							onChange={setSearch}
							value={search}
							onToggle={setHasFloatingSearch}
							placeholder={searchPlaceholder}
						/>
					)}
					{hasFeature('sort') && order && (
						<PlaylistOrderButton
							onChange={setOrder}
							order={order}
						/>
					)}
					{(hasFeature('popup') || !playlistConfig) && (
						<PopupButton />
					)}
				</div>
			</div>
			{currentTab === 'transcript' && transcript ? (
				<Transcript
					isNarrow={isSmall}
					search={transcriptSearch}
					showLabel={isTranscriptFloating}
				>
					{transcript}
				</Transcript>
			) : (
				<PlaylistItems
					showFeatured={currentTab === 'featured'}
					search={episodesSearch}
					shouldReverse={'asc' === order}
					allowTwoColumns={allowTwoColumns}
				/>
			)}
		</div>
	);
};

Playlist.defaultProps = {
	allowTwoColumns: true,
};

export default withPlayerState(Playlist);
