/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { ListIcon } from 'icons';
import { withConfig, withConfigProps } from 'HOC/withConfig';
import { withPlayerState, withPlayerStateProps } from 'HOC/withPlayerState';
import Button, { ButtonProps } from 'components/Button';
import Playlist from 'components/Playlist';
import PopoverButton from 'components/PopoverButton';

type ArchiveButtonProps = withConfigProps & withPlayerStateProps;

const ArchiveButton: FC<ArchiveButtonProps> = ({
	config,
	playerState: { isPlaylistVisible },
	setPlayerState,
}) => {
	if (!config || !config.playlist) {
		return null;
	}

	const props: ButtonProps = {
		buttonStyle: 'secondary',
		className: 'archive-button',
		icon: <ListIcon />,
	};

	if (config.sticky) {
		return (
			<PopoverButton
				{...props}
				popoverContent={
					<Playlist
						config={config.playlist}
						allowTwoColumns={false}
					/>
				}
				popoverClassName='fbx-archive-popover'
			/>
		);
	}

	return (
		<Button
			{...props}
			isActive={isPlaylistVisible}
			onClick={() =>
				setPlayerState({
					isPlaylistVisible: !isPlaylistVisible,
				})
			}
		/>
	);
};

export default withPlayerState(withConfig(ArchiveButton));
