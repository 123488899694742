/**
 * External dependencies
 */
import classnames from 'classnames';
import React, { FC, forwardRef, Ref, useState, useEffect } from 'react';
import { kebabCase } from 'lodash';

/**
 * Internal dependencies
 */
import { Button, EnhancedSlider } from 'components';
import { useAudioComponent } from 'context';
import { VolumeIcon, VolumeSlashedIcon } from 'icons';
import type { ButtonProps } from 'components/Button';

export type VolumeControlProps = {
	orientation?: 'horizontal' | 'vertical';
	direction?: 'toTop' | 'toBottom';
	ref?: Ref<HTMLDivElement>;
} & ButtonProps;

type VolumeControlState = {
	muted: boolean;
	value: number;
};

const VolumeControl: FC<VolumeControlProps> = forwardRef<
	HTMLDivElement,
	VolumeControlProps
>(({ direction = 'toTop', orientation, ...otherProps }, ref) => {
	const [state, setState] = useState<VolumeControlState>({
		muted: false,
		value: 0,
	});

	const audioComponent = useAudioComponent();

	useEffect(() => {
		if (audioComponent) {
			const sync = () =>
				setState({
					muted: audioComponent.muted,
					value: audioComponent.volume * 100,
				});

			audioComponent.addVolumeChangeListener(sync);

			sync();

			return () => audioComponent.removeVolumeChangeListener(sync);
		}
	}, [audioComponent]);

	const setValues: ({
		muted,
		value,
	}: {
		muted: boolean;
		value: number;
	}) => void = ({ muted, value }) => {
		if (!audioComponent) {
			return;
		}

		audioComponent.volume = value / 100;
		audioComponent.muted = muted;
	};

	const changeMuted = () => {
		const newValue = state.muted && 0 === state.value ? 100 : state.value;

		setValues({
			muted: !state.muted,
			value: newValue,
		});
	};

	const onChange = (value: number) => {
		setValues({
			value,
			muted: 0 === value,
		});
	};

	const sliderValue = state.muted ? 0 : state.value;

	const buttonProps = {
		buttonStyle: 'control',
		...otherProps,
	} as ButtonProps;

	const directionClass = kebabCase(direction);

	const wrapClassName = classnames(
		'fbx-volume-control',
		`fbx-is-${buttonProps.buttonStyle}`,
		`fbx-is-${orientation}`,
		/**
		 * This needs to be a tempate literal for Classname Prefix Loader to work properly. Also the classname needs to be
		 * defined as a separate constant because it does not work when `fbx-kebabCase` is used here directly.
		 */
		`fbx-${directionClass}`
	);

	return (
		<div className={wrapClassName} ref={ref}>
			<div className='fbx-volume-slider'>
				<EnhancedSlider
					value={sliderValue}
					tooltip={false}
					onChange={onChange}
					orientation={orientation}
				/>
			</div>
			<Button
				{...buttonProps}
				className='fbx-volume-control-button'
				onClick={changeMuted}
				label="Change volume"
			>
				{state.muted ? <VolumeSlashedIcon /> : <VolumeIcon />}
			</Button>
		</div>
	);
});

VolumeControl.defaultProps = {
	orientation: 'horizontal',
};

export default VolumeControl;
