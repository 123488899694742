/**
 * External dependencies
 */
import classnames from 'classnames';
import React, { FC } from 'react';

type PodcastImageProps = {
	src: string;
	alt: string;
	url?: string;
	size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
};

const PodcastImage: FC<PodcastImageProps> = ({ alt, src, url, size }) => {
	if (!src || !alt) {
		return null;
	}

	const image = <img src={src} alt={alt} />;

	const className = classnames('fbx-podcast-image', `fbx-is-size-${size}`);

	return (
		<div className={className}>
			{url ? (
				<a href={url} target="_blank" rel="noopener noreferrer">
					{image}
					<div className='fbx-overlay'>See more episodes</div>
				</a>
			) : (
				image
			)}
		</div>
	);
};

PodcastImage.defaultProps = {
	size: 'medium',
};

export default PodcastImage;
