/**
 * External dependencies
 */
import { md5 } from 'pure-md5';
import { render } from 'react-dom';
import React, { StrictMode } from 'react';

/**
 * Internal dependencies
 */
import { ConfigProvider } from 'context/config';
import { IObjectKeys, PlayerConfig } from 'interfaces';
import { PlayerState } from 'components/Player/types';
import { setBaseUrl, setDev } from 'utils';
import { setWrapper } from './wrapper';
import Player from 'components/Player';
import styles from 'styles/main.scss';
import type { PlayerType } from 'types';

const renderStyles = (hash: string): void => {
	const playerId = `fusebox-player-${hash}`;
	const popoversId = `fusebox-popovers-${hash}`;
	const styleElement = document.createElement('style');

	const css = document.createTextNode(
		styles
			.toString()
			.replace(
				/((\.fbx-player|\.fbx-popover)[,.\s[])/gi,
				(match, ...args) =>
					'player' === match.substr(5, 6)
						? `#${playerId}${args[0]}`
						: `#${popoversId} ${args[0]}`
			)
	);

	styleElement.setAttribute('type', 'text/css');
	styleElement.appendChild(css);

	document.head.appendChild(styleElement);
};

export const renderPlayer = (
	scriptElement: HTMLScriptElement,
	initialConfig?: PlayerConfig,
	initialState?: PlayerState
) => {
	if (!scriptElement.parentNode) {
		return;
	}

	const { type, hash, track, apiUrl, dev } = scriptElement.dataset;

	if (!type && !window.FuseboxPlayerAPIKey) {
		return;
	}

	if (
		(('archive' === type && !hash) || ('track' === type && !track)) &&
		!window.FuseboxPlayerAPIKey
	) {
		return;
	}

	const requestParams = { hash, track };
	const params: IObjectKeys = {};
	const scriptUrl = new URL(scriptElement.src);

	scriptUrl.searchParams.forEach((value, key) => {
		if ('[]' === key.substr(key.length - 2)) {
			key = key.substr(0, key.length - 2);

			if (!params[key]) {
				params[key] = [];
			}

			params[key].push(value);
		} else {
			params[key] = value;
		}
	});

	if ('localhost:3000' === window.location.host && apiUrl) {
		setBaseUrl(apiUrl);
	}

	if ('true' === dev) {
		setDev();
	}

	const wrapper = document.createElement('div');
	wrapper.classList.add('fbx-player-wrapper');

	setWrapper(wrapper);

	if (scriptElement.closest('body')) {
		scriptElement.parentNode.replaceChild(wrapper, scriptElement);
	} else {
		document.body.appendChild(wrapper);
	}

	const configId =
		hash || md5(track! || Math.random().toString()).substr(0, 12);

	renderStyles(configId);

	render(
		<StrictMode>
			<ConfigProvider
				configId={configId}
				initialConfig={initialConfig}
				params={params}
				requestParams={requestParams}
				type={type as PlayerType}
				wrapper={wrapper}
			>
				<Player initialState={initialState} />
			</ConfigProvider>
		</StrictMode>,
		wrapper
	);
};
