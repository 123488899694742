/**
 * External dependencies
 */
import { unmountComponentAtNode } from 'react-dom';

/**
 * Internal dependencies
 */
import { getWrapper } from './wrapper';

export const removePlayer = () => {
	const wrapper = getWrapper();

	if (wrapper) {
		unmountComponentAtNode(wrapper);
		wrapper.remove();
	}
};
