/**
 * External dependencies
 */
import { map } from 'lodash';

/**
 * Internal dependencies
 */
import { buildApiUrl } from 'utils/api';
import { Capability, Capabilities, isValidCapability } from './capabilities';
import { PlayerAPI } from './player-api';
import type { PlayerConfig } from 'interfaces';
import type { PublicPlayerAPI } from './player-api';

let capabilities: Capabilities = [];
let isAuthorized: boolean = false;
let PlayerAPIInstance: PlayerAPI | undefined = undefined;

/**
 * Authorize API key
 *
 * @param  key API key.
 * @return     Promise resolving to boolean value.
 */
export const authorizeApiKey = async (key: string): Promise<boolean> => {
	const response = await fetch(buildApiUrl('players/authorize'), {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${key}`,
		},
	});

	if (200 === response.status) {
		const data: Array<string> = await response.json();

		if (!Array.isArray(data)) {
			return false;
		}

		for (const item of data) {
			if (item.substr(0, 10) !== 'playerApi:') {
				continue;
			}

			const cap = item.substr(10);

			if (isValidCapability(cap)) {
				capabilities.push(cap);
			}
		}

		if (!capabilities.length) {
			return false;
		}

		console.log(`Fusebox Player API initialized`);

		isAuthorized = true;
		return true;
	} else if (401 === response.status) {
		throw new Error('provided API key is invalid');
	} else {
		throw new Error('unknown authorization error');
	}
};

export const getPlayerAPI = (): PlayerAPI | undefined => {
	if (!PlayerAPIInstance && window.FuseboxPlayerAPIKey) {
		if (true === isAuthorized) {
			PlayerAPIInstance = new PlayerAPI(capabilities);

			window.fuseboxPlayer = (id: string) => PlayerAPI.getInstance(id);
		}
	}

	return PlayerAPIInstance;
};

export const loadApi = async (): Promise<boolean> => {
	if (window.FuseboxPlayerAPIKey) {
		try {
			await authorizeApiKey(window.FuseboxPlayerAPIKey);
			return true;
		} catch (e: any) {
			if (e.message) {
				console.log(`Fusebox Player API error: ${e.message}`);
			}
		}
	}

	return false;
};

export const logExtendedAPIUsage = async ({
	title,
	tracks,
}: PlayerConfig): Promise<void> => {
	if (!window.FuseboxPlayerAPIKey) {
		return;
	}

	const body = new FormData();

	body.append('key', window.FuseboxPlayerAPIKey);
	body.append('title', title);

	for (const file of map(tracks, (t) => t.file)) {
		file && body.append('track_file[]', file);
	}

	for (const title of map(tracks, (t) => t.title)) {
		body.append('track_title[]', title);
	}

	try {
		const response = await fetch(buildApiUrl('players/usage'), {
			body,
			headers: {
				Accept: 'application/json',
			},
			method: 'POST',
		});

		if (response.status !== 200) {
			throw new Error(
				`Logging request failed with status code ${response.status}`
			);
		}
	} catch (e: any) {
		console.log(`Fusebox Player logging error: ${e.message}`);
	}
};

export { PlayerAPI, isValidCapability };
export type { Capabilities, Capability, PublicPlayerAPI };
