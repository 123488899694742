/**
 * External dependencies
 */
import bind from 'bind-decorator';
import React, { Component } from 'react';

/**
 * Internal dependencies
 */
import type { Track } from 'types';
import { ConfigContext } from 'context/config';

type AudioProps = {
	episode: Track;
	onLoaded?: () => void;
	onLoadStart?: () => void;
	onPause?: () => void;
	onPlay?: () => void;
	onTimeUpdate?: () => void;
	preventAutoPlay?: boolean;
	src?: string;
};

type AudioState = {
	currentTime: number;
	isLoaded: boolean;
	isLoading: boolean;
	muted: boolean;
	playbackRate: number;
	shouldLoad: boolean;
	shouldPlay: boolean;
	volume: number;
};

class Audio extends Component<AudioProps, AudioState> {
	static contextType = ConfigContext;

	state = {
		currentTime: 0,
		isLoaded: false,
		isLoading: false,
		muted: false,
		playbackRate: 1,
		shouldLoad: false,
		shouldPlay: false,
		volume: 1,
	};

	audioElement?: HTMLAudioElement;
	volumeChangeListeners: Array<() => void> = [];

	get paused(): boolean {
		return this.audioElement && this.state.isLoaded
			? this.audioElement.paused
			: !this.state.shouldPlay;
	}

	get volume(): number {
		return this.audioElement && this.state.isLoaded
			? this.audioElement.volume
			: this.state.volume;
	}

	set volume(volume: number) {
		if (this.audioElement && this.state.isLoaded) {
			this.audioElement.volume = volume;
		} else {
			this.setState({ volume }, this.handleVolumeChange);
		}
	}

	get duration(): number {
		return this.audioElement && this.state.isLoaded
			? this.audioElement.duration
			: this.props.episode.duration || 0;
	}

	get currentTime(): number {
		return this.audioElement && this.state.isLoaded
			? this.audioElement.currentTime
			: this.state.currentTime;
	}

	set currentTime(currentTime: number) {
		if (this.audioElement && this.state.isLoaded) {
			this.setCurrentTime(currentTime);
		} else {
			this.setState({ currentTime });

			if (this.props.onTimeUpdate) {
				this.props.onTimeUpdate();
			}
		}
	}

	get muted(): boolean {
		return this.audioElement && this.state.isLoaded
			? this.audioElement.muted
			: this.state.muted;
	}

	set muted(muted: boolean) {
		if (this.audioElement && this.state.isLoaded) {
			this.audioElement.muted = muted;
		} else {
			this.setState({ muted });
		}
	}

	get playbackRate(): number {
		return this.audioElement && this.state.isLoaded
			? this.audioElement.playbackRate
			: this.state.playbackRate;
	}

	set playbackRate(playbackRate: number) {
		if (this.audioElement && this.state.isLoaded) {
			this.audioElement.playbackRate = playbackRate;
		} else {
			this.setState({ playbackRate });
		}
	}

	addVolumeChangeListener(listener: () => void): void {
		for (const existingListener of this.volumeChangeListeners) {
			if (existingListener === listener) {
				return;
			}
		}

		this.volumeChangeListeners.push(listener);
	}

	removeVolumeChangeListener(listener: () => void): void {
		const index = this.volumeChangeListeners.indexOf(listener);

		if (-1 !== index) {
			this.volumeChangeListeners.splice(index, 1);
		}
	}

	setCurrentTime(value: number): void {
		if (this.audioElement) {
			this.audioElement.currentTime = Math.max(
				Math.min(value, this.audioElement.duration || 0),
				0
			);
		}
	}

	play() {
		if (this.audioElement && this.state.isLoaded) {
			this.audioElement.play();
		} else {
			this.setState({ shouldPlay: true });

			if (this.props.onPlay) {
				this.props.onPlay();
			}
		}
	}

	pause() {
		if (this.audioElement && this.state.isLoaded) {
			this.audioElement.pause();
		} else {
			this.setState({ shouldPlay: false });

			if (this.props.onPause) {
				this.props.onPause();
			}
		}
	}

	@bind
	setAudioElement(audioElement: HTMLAudioElement): void {
		if (!this.audioElement) {
			this.audioElement = audioElement;
		}
	}

	@bind
	handleVolumeChange(): void {
		for (const listener of this.volumeChangeListeners) {
			listener();
		}
	}

	@bind
	handleLoadedMetadata(): void {
		this.setState({
			isLoading: false,
			isLoaded: true,
		});
	}

	getSnapshotBeforeUpdate(prevProps: AudioProps) {
		if (this.props.episode !== prevProps.episode && this.audioElement) {
			this.pause();

			return {
				currentTime: 0,
				muted: this.audioElement.muted,
				playbackRate: this.audioElement.playbackRate,
				volume: this.audioElement.volume,
			};
		}

		return null;
	}

	componentDidUpdate(
		prevProps: AudioProps,
		prevState: AudioState,
		snapshot: AudioState | null
	) {
		const isNewEpisode = this.props.episode.file !== prevProps.episode.file;
		const shouldLoad =
			this.state !== prevState &&
			!this.state.shouldLoad &&
			!this.state.isLoading &&
			!this.state.isLoaded;

		if (isNewEpisode || shouldLoad) {
			let newState = {
				isLoaded: false,
				isLoading: true,
				shouldLoad: true,
				shouldPlay:
					this.props.preventAutoPlay !== true && isNewEpisode
						? true
						: this.state.shouldPlay,
			};

			if (null !== snapshot) {
				newState = {
					...newState,
					...snapshot,
				};
			}

			this.setState(newState);

			if (this.props.onLoadStart) {
				this.props.onLoadStart();
			}
		}

		if (this.state.isLoaded && !prevState.isLoaded) {
			if (this.audioElement) {
				this.audioElement.volume = this.state.volume;
				this.audioElement.playbackRate = this.state.playbackRate;
				this.audioElement.currentTime = this.state.currentTime;
				this.audioElement.muted = this.state.muted;
			}

			if (this.state.shouldPlay) {
				this.play();
			}

			if (this.props.onLoaded) {
				this.props.onLoaded();
			}
		}
	}

	render() {
		const { onPause, onPlay, onTimeUpdate, episode } = this.props;

		const { shouldLoad } = this.state;

		const file = this.context!.proxied
			? `${episode.file}?fbx=player`
			: episode.file;

		return (
			<audio
				src={shouldLoad ? file : undefined}
				controls={false}
				ref={this.setAudioElement}
				onLoadedMetadata={this.handleLoadedMetadata}
				onPlay={() => onPlay && onPlay()}
				onPause={() => onPause && onPause()}
				onTimeUpdate={() => onTimeUpdate && onTimeUpdate()}
				onVolumeChange={this.handleVolumeChange}
			/>
		);
	}
}

export default Audio;
