/**
 * External dependencies
 */
import { filter } from 'lodash';

/**
 * Internal dependencies
 */
import { getBaseUrl } from 'utils/api';
import type { PlayerConfig } from 'interfaces';
import type { PlayerState } from 'components/Player/types';

type ReceiverCallback = (data: any) => void;
type ReceiverCallbackEntry = {
	type: string;
	callback: ReceiverCallback;
};

let windowReference: Window | null = null;
let playerId: string;

const receivers: Array<ReceiverCallbackEntry> = [];

export const initBroadcast = (ref: Window, id: string): void => {
	windowReference = ref;
	playerId = id;

	window.addEventListener('message', (e) => {
		if (
			'object' === typeof e.data &&
			e.data.id === playerId &&
			e.data.source &&
			'fusebox-player' === e.data.source
		) {
			const filteredReceivers = filter(
				receivers,
				(o) => o.type === e.data.type
			);

			for (const receiver of filteredReceivers) {
				receiver.callback(e.data.data);
			}
		}
	});
};

export const postMessage = (type: string, data?: any): void => {
	if (windowReference) {
		windowReference.postMessage(
			{
				source: 'fusebox-player',
				id: playerId,
				type,
				data,
			},
			'*'
		);
	}
};

export const addReceiver = (type: string, callback: ReceiverCallback): void => {
	receivers.push({
		type,
		callback,
	});
};

export const openWindow = (
	config: PlayerConfig,
	state: PlayerState,
	onClose?: () => void
): Window | null => {
	const baseUrl = getBaseUrl();
	const type = config.playlist ? 'archive' : 'track';
	const episodeNo =
		'track' === type ? `${state.currentEpisode?.episodeNo}/` : '';
	const url = `${baseUrl}/embed/player/${type}/${
		config.hash || config.showHash
	}/${episodeNo}`;
	const title = `${config.title} — Fusebox Player`;
	const params = 'width=1400,height=800,location=no,menubar=no';

	const ref = window.open(url, title, params);

	if (ref) {
		initBroadcast(ref, config.id);

		addReceiver('ready', () => {
			const { currentEpisode, currentTime, isPlaying } = state;

			postMessage('init', {
				config: {
					...config,
					sticky: false,
					isStandalone: true,
				},
				state: {
					currentEpisode,
					currentTime,
					isPlaying,
				},
			});
		});

		const pollTimer = setInterval(() => {
			if (false !== ref.closed) {
				clearInterval(pollTimer);
				onClose && onClose();
			}
		}, 250);
	}

	return windowReference;
};
