/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { PlayerConfig } from 'interfaces';
import { useConfig } from 'context/config';
import type { ShadowInjected } from 'types';

type withConfigProps = {
	config: PlayerConfig;
};

const withConfig = <P extends object>(
	Component: React.ComponentType<P>
): FC<ShadowInjected<P, withConfigProps>> => {
	return (props: ShadowInjected<P, withConfigProps>) => {
		const config = useConfig();

		return <Component config={config} {...(props as P)} />;
	};
};

export { withConfig };

export type { withConfigProps };
