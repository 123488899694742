import type { IObjectKeys } from 'interfaces';

const allowedHosts = ['app.fusebox.fm', 'app.fusebox', 'dev.app.fusebox.fm'];

/**
 * Checks if current window location is one of the allowed hosts.
 *
 * @return True, it this is allowed host, false otherwise.
 */
export const isAllowedHost = (): boolean =>
	allowedHosts.includes(window.location.host);

let baseUrl: string = isAllowedHost()
	? `${window.location.protocol}//${window.location.host}`
	: 'https://app.fusebox.fm';

export const apiUrl = '/api/';

export const setBaseUrl = (url: string): void => {
	baseUrl = url;
};

export const setDev = (): void => setBaseUrl('https://dev.app.fusebox.fm');

export const getBaseUrl = (): string => baseUrl;

export const getBaseApiUrl = (): string => {
	return `${getBaseUrl().replace(/\/$/, '')}${apiUrl}`;
};

export const buildApiUrl = (path: string, params?: IObjectKeys): string => {
	const searchParams = new URLSearchParams();

	for (const key in params) {
		if (Array.isArray(params[key])) {
			for (const value of params[key]) {
				searchParams.append(`${key}[]`, value);
			}
		} else {
			searchParams.append(key, params[key as any]);
		}
	}

	const baseUrl = getBaseApiUrl();

	const url: URL = new URL(baseUrl + path);

	url.search = searchParams.toString();

	return url.toString();
};

export const apiCall = async <T = any>(url: string): Promise<T | false> => {
	try {
		const response = await fetch(url, {
			headers: {
				Accept: 'application/json',
			},
		});

		const isJson =
			'application/json' === response.headers.get('Content-Type');

		const data = isJson ? await response.json() : false;

		if (200 !== response.status) {
			if (data && data.message) {
				throw new Error(data.message);
			} else if (false === data) {
				const text = await response.text();

				throw new Error(text);
			}

			throw new Error('Unknown error');
		}

		return data;
	} catch (e) {
		console.log(`Fusebox Player loading error: ${e.message}`);
		return false;
	}
};
