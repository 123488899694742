/**
 * External dependencies
 */
import React, { FC, useState } from 'react';
import Slider, { SliderProps } from 'react-rangeslider';

type EnhancedSliderState = {
	isChanging: boolean;
	slider?: Slider;
};

const EnhancedSlider: FC<SliderProps> = (props) => {
	const [state, setState] = useState<EnhancedSliderState>({
		isChanging: false,
	});

	const onChange: (value: number, e: MouseEvent) => void = (value, e) => {
		if (!state.isChanging && state.slider) {
			setState({ ...state, isChanging: true });

			state.slider.handleStart(e);
		}

		props.onChange && props.onChange(value, e);
	};

	const onChangeComplete: (e: MouseEvent) => void = (e) => {
		setState({ ...state, isChanging: false });

		props.onChangeComplete && props.onChangeComplete(e);
	};

	const sliderProps = {
		...props,
		onChange,
		onChangeComplete,
	};

	return (
		<Slider
			{...sliderProps}
			ref={(slider) =>
				!state.slider && slider && setState({ ...state, slider })
			}
		/>
	);
};

export default EnhancedSlider;
