/**
 * External dependencies
 */
import classnames from 'classnames';
import React, { FC, useState } from 'react';

/**
 * Internal dependencies
 */
import { formatTime } from 'utils';
import { useAudioComponent } from 'context';
import { withAudioUtils, withAudioUtilsProps } from 'HOC';
import { TimeDisplay, EnhancedSlider } from 'components';
import { useCurrentEpisode } from 'hooks';

type ProgressBarProps = {
	showTimeDisplay?: boolean;
	isThick?: boolean;
} & withAudioUtilsProps;

type ProgressBarState = {
	value: number;
	isChanging: boolean;
};

const ProgressBar: FC<ProgressBarProps> = ({ isThick, showTimeDisplay }) => {
	const [state, setState] = useState<ProgressBarState>({
		value: 0,
		isChanging: false,
	});

	const audioComponent = useAudioComponent();
	const currentEpisode = useCurrentEpisode();

	const currentValue = state.isChanging
		? state.value
		: audioComponent?.currentTime || 0;

	const onChange = (value: number, e: MouseEvent) => {
		setState({ ...state, value });

		if (!state.isChanging && audioComponent?.duration) {
			audioComponent.currentTime = value;
		}
	};

	const onChangeStart = () => {
		if (!audioComponent) {
			return;
		}

		audioComponent.pause();
		setState({
			isChanging: true,
			value: audioComponent.currentTime,
		});
	};

	const onChangeComplete = () => {
		if (state.isChanging) {
			setState({ ...state, isChanging: false });
		}

		if (!audioComponent) {
			return;
		}

		audioComponent.currentTime = state.value;
		audioComponent.play();
	};

	const className = classnames('fbx-progress-bar', {
		'fbx-is-changing': state.isChanging,
		'fbx-is-thick': isThick,
	});

	const max = audioComponent?.duration || 0.1;

	const duration =
		showTimeDisplay &&
		(audioComponent?.duration || currentEpisode?.duration || 0);

	return (
		<div className={className}>
			{showTimeDisplay && (
				<TimeDisplay time={audioComponent?.currentTime || 0} />
			)}
			<EnhancedSlider
				value={currentValue}
				min={0}
				max={max}
				onChange={onChange}
				format={formatTime}
				tooltip={!!audioComponent?.duration}
				onChangeStart={onChangeStart}
				onChangeComplete={onChangeComplete}
			/>
			{duration && <TimeDisplay time={duration} />}
		</div>
	);
};

ProgressBar.defaultProps = {
	showTimeDisplay: false,
};

export default withAudioUtils(ProgressBar);
