/**
 * External dependencies
 */
import { trim } from 'lodash';
import classnames from 'classnames';
import React, { FC, useCallback, useEffect, useRef } from 'react';

/**
 * Internal dependencies
 */
import Scrollable from 'components/Scrollable';

type TranscriptProps = {
	children: string;
	isNarrow: boolean;
	search?: string;
	showLabel: boolean;
};

const Transcript: FC<TranscriptProps> = ({
	children,
	isNarrow,
	search,
	showLabel,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	const maybeSearch = useCallback(
		(content: string) => {
			if (search) {
				const trimmedSearch = trim(search);

				if (trimmedSearch) {
					return content.replaceAll(
						new RegExp(trimmedSearch, 'gi'),
						`<em class="search-phrase">$&</em>`
					);
				}
			}

			return content;
		},
		[search]
	);

	useEffect(() => {
		if (!ref.current) {
			return;
		}

		const first = ref.current.querySelector('.search-phrase');

		if (first) {
			first.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}, [ref, search]);

	return (
		<Scrollable
			className={classnames('fbx-transcript', { 'fbx-is-narrow': isNarrow })}
		>
			{showLabel && <h2 className='fbx-transcript-title'>Transcript</h2>}
			<div
				className='fbx-transcript-content'
				dangerouslySetInnerHTML={{ __html: maybeSearch(children) }}
				ref={ref}
			/>
		</Scrollable>
	);
};

export default Transcript;
