/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { useAudioComponent } from 'context/audio';
import type { ShadowInjected } from 'types';

type withAudioUtilsProps = {
	changeAudioTime: (time: number) => void;
	audioComponent: HTMLAudioElement;
};

const withAudioUtils = <P extends object>(
	Component: React.ComponentType<P>
): FC<ShadowInjected<P, withAudioUtilsProps>> => {
	return (props: ShadowInjected<P, withAudioUtilsProps>) => {
		const audioComponent = useAudioComponent();

		const changeAudioTime: (time: number) => void = (time) => {
			if (!audioComponent) {
				return;
			}

			audioComponent.currentTime = Math.max(
				Math.min(time, audioComponent.duration),
				0
			);
		};

		return (
			<Component
				changeAudioTime={changeAudioTime}
				audioComponent={audioComponent}
				{...(props as P)}
			/>
		);
	};
};

export { withAudioUtils };

export type { withAudioUtilsProps };
