/**
 * External dependencies
 */
import React, { createContext, FC, ReactNode, useContext } from 'react';

/**
 * Internal dependencies
 */
import defaultPlayerState from 'components/Player/defaultState';
import type { PlayerState, SetPlayerState } from 'components/Player/types';

type PlayerStateContextValue = [PlayerState, SetPlayerState];

type PlayerStateProviderProps = {
	children: ReactNode;
	value: PlayerStateContextValue;
};

type PlayerStateConsumerProps = {
	children: (state: PlayerStateContextValue) => ReactNode;
};

const PlayerStateContext = createContext<PlayerStateContextValue>([
	defaultPlayerState,
	() => {},
]);

const PlayerStateProvider: FC<PlayerStateProviderProps> = ({
	children,
	value,
}) => {
	return (
		<PlayerStateContext.Provider value={value}>
			{value && children}
		</PlayerStateContext.Provider>
	);
};

const PlayerStateConsumer: FC<PlayerStateConsumerProps> = ({ children }) => {
	return (
		<PlayerStateContext.Consumer>
			{(state) => state && children(state)}
		</PlayerStateContext.Consumer>
	);
};

const usePlayerState = () => {
	return useContext(PlayerStateContext);
};

export {
	PlayerStateConsumer,
	PlayerStateContext,
	PlayerStateProvider,
	usePlayerState,
};
