/**
 * External dependencies
 */
import { throttle } from 'lodash';
import React, { FC, useState, RefObject } from 'react';
import useResizeObserver from 'use-resize-observer';

/**
 * Internal dependencies
 */
import type { ShadowInjected } from 'types';

export type withResizeObserverProps = {
	height: number;
	setRef: (ref: RefObject<HTMLDivElement>) => void;
	width: number;
};

export const withResizeObserver = <P extends object>(
	Component: React.ComponentType<P>
): FC<ShadowInjected<P, withResizeObserverProps>> => {
	return (props: ShadowInjected<P, withResizeObserverProps>) => {
		const [height, setHeight] = useState<number>();
		const [ref, setRef] = useState<RefObject<HTMLDivElement>>();
		const [width, setWidth] = useState<number>();

		useResizeObserver({
			onResize: throttle(({ width, height }) => {
				setHeight(height);
				setWidth(width);
			}, 200),
			ref,
		});

		return (
			<Component
				height={height}
				setRef={setRef}
				width={width}
				{...(props as P)}
			/>
		);
	};
};
