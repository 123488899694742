/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { formatTime } from 'utils';
import Separator from './Separator';
import { usePlayerState } from 'context';

type TimeDisplayProps = {
	time:
		| number
		| {
				current: number;
				total: number;
		  };
};

const TimeDisplay: FC<TimeDisplayProps> = ({ time }) => {
	const [{ isLoading }] = usePlayerState();

	const timeStrings: Array<string> | string =
		'object' === typeof time
			? [formatTime(time.current), formatTime(time.total)]
			: formatTime(time);

	const text =
		'object' === typeof time ? (
			isLoading ? (
				'Loading episode...'
			) : time.total !== 0 ? (
				<>
					{timeStrings[0]}
					<span className='fbx-separator'>
						<Separator />
					</span>
					{timeStrings[1]}
				</>
			) : null
		) : (
			timeStrings
		);

	return text ? <div className='fbx-time-display'>{text}</div> : null;
};

export default TimeDisplay;
