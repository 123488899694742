/**
 * External dependencies
 */
import React, { FC, useState } from 'react';

/**
 * Internal dependencies
 */
import { Button } from 'components';
import { TimeIcon } from 'icons';
import { useAudioComponent } from 'context';
import type { ButtonProps } from 'components/Button';

const SpeedButton: FC<ButtonProps> = (props) => {
	const [speedIndex, setSpeedIndex] = useState<number>(0);

	const audioComponent = useAudioComponent();

	const playbackRates = [1, 1.5, 1.75, 2, 0.5];

	const handleClick = () => {
		let newIndex = speedIndex + 1;

		if (newIndex >= playbackRates.length) {
			newIndex = 0;
		}

		if (audioComponent) {
			audioComponent.playbackRate = playbackRates[newIndex];
		}

		setSpeedIndex(newIndex);
	};

	const text = `${playbackRates[speedIndex]}x`;

	const buttonProps = {
		buttonStyle: 'control',
		...props,
	} as ButtonProps;

	const isSecondary = 'secondary' === buttonProps.buttonStyle;

	return (
		<Button
			{...buttonProps}
			onClick={handleClick}
			className='fbx-speed-button'
			icon={!isSecondary ? <TimeIcon /> : undefined}
			label="Change speed"
		>
			{text}
		</Button>
	);
};

export default SpeedButton;
