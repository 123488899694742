/**
 * External dependencies
 */
import React from 'react';

const Separator = () => {
	return (
		<svg viewBox="0 0 3 10" xmlns="http://www.w3.org/2000/svg">
			<path d="M.512 9.892l2-9" />
		</svg>
	);
};

export default Separator;
