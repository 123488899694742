/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { usePlayerState } from 'context/player-state';
import type { PlayerState, SetPlayerState } from 'components/Player/types';
import type { ShadowInjected } from 'types';

export type withPlayerStateProps = {
	playerState: PlayerState;
	setPlayerState: SetPlayerState;
};

export const withPlayerState = <P extends object>(
	Component: React.ComponentType<P>
): FC<ShadowInjected<P, withPlayerStateProps>> => {
	return (props: ShadowInjected<P, withPlayerStateProps>) => {
		const [state, setState] = usePlayerState();

		return (
			<Component
				playerState={state}
				setPlayerState={setState}
				{...(props as P)}
			/>
		);
	};
};
