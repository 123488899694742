/**
 * External dependencies
 */
import bind from 'bind-decorator';
import classnames from 'classnames';
import React, {
	Component,
	ContextType,
	ReactNode,
	RefObject,
	createRef,
} from 'react';
import { find } from 'lodash';

/**
 * Internal dependencies
 */
import { AudioComponentProvider } from 'context/audio';
import { ConfigContext } from 'context/config';
import { contrast } from 'utils/colors';
import { getPlayerAPI } from 'api';
import { PlayerStateProvider } from 'context/player-state';
import { postMessage, addReceiver } from 'utils/broadcast';
import { PoweredByIcon } from 'icons';
import {
	withResizeObserver,
	withResizeObserverProps,
} from 'HOC/withResizeObserver';
import Audio from 'components/Audio';
import PlayerLayout from 'components/PlayerLayout';
import Playlist from 'components/Playlist';
import type { PlayerAPI, PublicPlayerAPI } from 'api';
import type { PlayerConfig } from 'interfaces';
import type { PlayerProps, PlayerState, Theme } from './types';
import defaultState from './defaultState';

class Player extends Component<PlayerProps, PlayerState> {
	static contextType = ConfigContext;
	static config?: PlayerConfig;

	static getDerivedStateFromProps(
		{ width, height }: withResizeObserverProps,
		{ playerWidth, playerHeight }: PlayerState
	) {
		if (
			Player.config &&
			(width !== playerWidth || height !== playerHeight)
		) {
			return {
				playerWidth: width,
				playerHeight: height,
				layout: Player.getLayout(width, Player.config),
			};
		}

		return null;
	}

	static getLayout(width: number, config: PlayerConfig) {
		const { style, sticky, cover } = config;

		const narrowBreakpoint = sticky
			? 620
			: 'large' === style && cover
			? 700
			: 600;

		const mediumBreakpoint = sticky
			? 820
			: 'large' === style && cover
			? 800
			: 700;

		const largeBreakpoint = sticky ? 1199 : false;
		const wideBreakpoint = sticky ? 1023 : false;

		return narrowBreakpoint > width
			? 'narrow'
			: mediumBreakpoint > width
			? 'medium'
			: largeBreakpoint && largeBreakpoint < width
			? 'full'
			: wideBreakpoint && wideBreakpoint < width
			? 'wider'
			: 'wide';
	}

	api?: PlayerAPI;
	audioComponent: Audio | undefined;
	context: ContextType<typeof ConfigContext>;
	playerRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
	state = defaultState;
	tracksSnapshot: PlayerConfig['tracks'] | undefined;

	constructor(props: PlayerProps, config?: PlayerConfig) {
		super(props);

		/**
		 * This is used to detect tracks change
		 * @see `componentDidUpdate` method
		 */
		this.tracksSnapshot = config?.tracks;

		this.api = getPlayerAPI();

		if (config?.playlist) {
			this.state.isPlaylistVisible = !config.playlist.defaultHidden;
		}

		if (props.initialState) {
			this.state = {
				...this.state,
				...props.initialState,
			};
		}
	}

	@bind
	setAudioComponent(audioComponent: Audio) {
		if (undefined === this.audioComponent) {
			this.audioComponent = audioComponent;

			if (this.api) {
				this.playerRef.current!.playerAPI =
					this.api.getPublicAPI() as PublicPlayerAPI;

				this.api.setAudioComponent(this.audioComponent!);
			}

			if (
				this.state.currentTime > 0 &&
				this.state.currentTime < this.audioComponent!.duration
			) {
				this.audioComponent.currentTime = this.state.currentTime;
			}

			if (this.state.isPlaying) {
				this.audioComponent.play();
			}
		}
	}

	getPlayerTheme(): Theme {
		const { color } = this.context!;

		this.playerRef.current!.style.setProperty('--fusebox-accent', color);

		const colorContrast = contrast(color, '#fff');
		let theme: Theme;

		switch (colorContrast) {
			case 1:
				theme = 'white';
				break;
			case 21:
				theme = 'black';
				break;
			default:
				theme = 2.8 > colorContrast ? 'light' : 'dark';
		}

		return theme;
	}

	/**
	 * Set current episode in state when the config is loaded.
	 */
	componentDidMount() {
		this.props.setRef(this.playerRef);

		let currentEpisode = this.state.currentEpisode;

		if (!currentEpisode) {
			const { tracks } = this.context!;

			const searchParams = new URLSearchParams(window.location.search);

			if (searchParams.has('fusebox_track')) {
				currentEpisode =
					find(
						tracks,
						(o) =>
							o.episodeNo ===
							parseInt(
								searchParams.get('fusebox_track') as string
							)
					) || null;
			}

			if (!currentEpisode) {
				currentEpisode = this.getCurrentEpisodeFromTracks();
			}
		}

		this.setState({
			currentEpisode,
			initialized: true,
			layout: Player.getLayout(
				this.playerRef.current!.offsetWidth,
				this.context!
			),
			playerWidth: this.playerRef.current!.offsetWidth,
			theme: this.getPlayerTheme(),
		});

		if (!this.context!.isStandalone) {
			addReceiver('state', this.handleReceiveState.bind(this));
		}
	}

	getCurrentEpisodeFromTracks() {
		const { tracks } = this.context!;

		return tracks[
			this.context!.playlist?.order === 'desc' ? 0 : tracks.length - 1
		];
	}

	handleReceiveState({
		currentTime,
		currentEpisode,
		isPlaying,
	}: PlayerState) {
		const currentEpisodeChanged =
			currentEpisode &&
			this.state.currentEpisode?.episodeNo !== currentEpisode.episodeNo;
		const isPlayingChanged = this.state.isPlaying === isPlaying;

		if (currentEpisodeChanged || isPlayingChanged) {
			const newState = {};

			if (currentEpisodeChanged) {
				(newState as PlayerState).currentEpisode = currentEpisode;
			}

			if (isPlayingChanged) {
				(newState as PlayerState).isPlaying = isPlaying;
			}

			this.setState(newState, () => {
				this.audioComponent!.currentTime = currentTime;
			});
		} else if (this.audioComponent!.currentTime !== currentTime) {
			this.audioComponent!.currentTime = currentTime;
		}
	}

	componentDidUpdate(prevProps: {}, prevState: PlayerState) {
		const theme = this.getPlayerTheme();

		if (prevState.theme !== theme) {
			this.setState({ theme });
		}

		if (this.tracksSnapshot !== this.context?.tracks) {
			// Store new tracks as snapshot for future comparison.
			this.tracksSnapshot = this.context?.tracks;

			// Update currentEpisode state if tracks changed.
			this.setState({
				currentEpisode: this.getCurrentEpisodeFromTracks(),
				preventAutoPlay: true,
			});
		}

		if (this.state.disabled && !this.audioComponent!.paused) {
			this.audioComponent!.pause();
		}

		if (
			this.state.disabled !== prevState.disabled &&
			false === this.state.disabled &&
			this.state.isPlaying
		) {
			this.audioComponent!.play();
		}

		if (true === this.context!.isStandalone) {
			const { currentTime, currentEpisode, isPlaying } = this.state;

			postMessage('state', {
				currentTime,
				currentEpisode,
				isPlaying,
			});
		}

		if (
			this.state.preventAutoPlay !== prevState.preventAutoPlay &&
			this.state.preventAutoPlay
		) {
			this.setState({ preventAutoPlay: false });
		}
	}

	@bind
	updateTime() {
		if (!this.audioComponent) {
			return;
		}

		this.setState({ currentTime: this.audioComponent.currentTime });
	}

	/**
	 * Render component.
	 */
	render(): ReactNode {
		Player.config = this.context!;

		const {
			credits,
			id,
			isStandalone,
			playlist,
			position,
			sticky,
			style,
			tracks,
		} = this.context!;

		const {
			currentEpisode,
			disabled,
			initialized,
			isLoading,
			isPlaylistVisible,
			layout,
			layoutRef,
			preventAutoPlay,
			theme,
		} = this.state;

		const playerClassName = classnames('fbx-player', `fbx-has-${theme}-theme`, {
			'fbx-is-disabled': disabled,
			'fbx-is-loading': isLoading,
			'fbx-is-preview': window.FuseboxPlayerPreview,
			'fbx-is-sticky': sticky,
			[`fbx-has-position-${position}`]: sticky,
			[`fbx-is-layout-${layout}`]: !!layout,
			[`fbx-is-style-${style}`]: !sticky,
		});

		const hasPlaylist =
			(playlist && tracks.length > 1) || currentEpisode?.transcript;
		const shouldShowPlaylist =
			isPlaylistVisible !== undefined
				? isPlaylistVisible
				: !playlist?.defaultHidden;

		return (
			<div
				className={playerClassName}
				id={`fusebox-player-${id}`}
				ref={this.playerRef}
			>
				<PlayerStateProvider
					value={[this.state, this.setState.bind(this)]}
				>
					{currentEpisode && (
						<Audio
							episode={currentEpisode}
							onLoaded={() => this.setState({ isLoading: false })}
							onLoadStart={() =>
								currentEpisode.file &&
								this.setState({ isLoading: true })
							}
							onPause={() => this.setState({ isPlaying: false })}
							onPlay={() => this.setState({ isPlaying: true })}
							onTimeUpdate={this.updateTime}
							preventAutoPlay={preventAutoPlay}
							ref={this.setAudioComponent}
						/>
					)}
					{initialized && (
						<AudioComponentProvider value={this.audioComponent}>
							<PlayerLayout
								setRef={(ref) =>
									!layoutRef &&
									this.setState({ layoutRef: ref })
								}
							/>
							{hasPlaylist && !sticky && shouldShowPlaylist && (
								<Playlist
									config={playlist!}
									layoutRef={layoutRef}
									transcript={currentEpisode?.transcript}
								/>
							)}
						</AudioComponentProvider>
					)}
				</PlayerStateProvider>
				{!sticky && credits && !isStandalone && (
					<div className='fbx-credits'>
						<a
							href="https://fusebox.fm"
							target="_blank"
							rel="noopener noreferrer"
						>
							<PoweredByIcon />
						</a>
					</div>
				)}
			</div>
		);
	}
}

export default withResizeObserver(Player);
