/**
 * External dependencies
 */
import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { Button } from 'components';
import { ArrowIcon, ArcArrowIcon } from 'icons';
import { useAudioComponent } from 'context';
import { withAudioUtils, withAudioUtilsProps } from 'HOC';

type TimeShiftButtonProps = {
	deltaSeconds?: number;
	direction?: '>' | '<';
	isAlternative?: boolean;
};

const TimeShiftButton: FC<TimeShiftButtonProps & withAudioUtilsProps> = ({
	changeAudioTime,
	deltaSeconds,
	direction,
	isAlternative,
}) => {
	const audioComponent = useAudioComponent();

	const isForward = '>' === direction;
	const text = deltaSeconds + (isAlternative ? '' : 's');

	const handleClick = () => {
		if (null === audioComponent) {
			return;
		}

		const delta = isForward ? deltaSeconds : -deltaSeconds!;

		audioComponent && changeAudioTime(audioComponent.currentTime + delta!);
	};

	/**
	 * `prettier/prettier` rule needs to be disabled here. If the class name is not quoted, it doesn't get prefixed,
	 * which breaks the animation.
	 */
	/* eslint-disable prettier/prettier */
	const className = classnames('fbx-time-shift-button', {
		'fbx-is-alternative': isAlternative,
		'fbx-backward': !isForward,
		'fbx-forward': isForward,
	});
	/* eslint-enable */

	const Icon = isAlternative ? ArcArrowIcon : ArrowIcon;

	return (
		<Button
			onClick={handleClick}
			className={className}
			buttonStyle={isAlternative ? 'none' : 'control'}
			icon={<Icon />}
			iconPosition={isForward ? 'right' : 'left'}
		>
			{text}
		</Button>
	);
};

TimeShiftButton.defaultProps = {
	deltaSeconds: 15,
	direction: '>',
	isAlternative: false,
};

export default withAudioUtils(TimeShiftButton);
