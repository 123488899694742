type RGB = {
	r: number;
	g: number;
	b: number;
};

/**
 * Get single color (reg, green, blue) value from hexadecimal color code.
 *
 * @param  color    Hexadecimal color code.
 * @param  position Color position (1 - red, 2 - green, 3 - blue).
 * @return          Single color value.
 */
export const getSingleColorValue = (
	color: string,
	position: number
): string | false => {
	if (!isValidColor(color)) {
		return false;
	}

	if ('#' === color[0]) {
		color = color.substr(1);
	}

	if (3 === color.length) {
		return color.substr(position - 1, 1).repeat(2);
	} else {
		return color.substr(position * 2 - 2, 2);
	}
};

/**
 * Check if given string is a valid hexadecimal color code
 *
 * @param  color Hexadecimal color code.
 * @return       Whether the color is valid.
 */
export const isValidColor = (color: string): boolean => {
	return /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);
};

/**
 * Convert hexadecimal color represented as #xxx,
 * xxx, #xxxxxx or xxxxxx to RGB integer values.
 *
 * @param  color Hexadecimal color.
 * @return       RGB values or false on invalid input.
 */
export const hex2RGB = (color: string): RGB | false => {
	if (!isValidColor(color)) {
		return false;
	}

	if ('#' === color[0]) {
		color = color.substr(1);
	}

	const r = parseInt(getSingleColorValue(color, 1) as string, 16);
	const g = parseInt(getSingleColorValue(color, 2) as string, 16);
	const b = parseInt(getSingleColorValue(color, 3) as string, 16);

	return { r, g, b };
};

/**
 * Get luminance value for give color.
 *
 * @param  color Hexadecimal color code.
 * @return       Luminance value.
 */
export const luminance = (color: string): number | false => {
	const colorRGB = hex2RGB(color);

	if (!colorRGB) {
		return false;
	}

	for (const key in colorRGB) {
		let item: number = colorRGB[key as keyof RGB];
		item /= 255;
		item =
			item <= 0.03928
				? item / 12.92
				: Math.pow((item + 0.055) / 1.055, 2.4);

		colorRGB[key as keyof RGB] = item;
	}

	return colorRGB.r * 0.2126 + colorRGB.g * 0.7152 + colorRGB.b * 0.0722;
};

/**
 * Get contrast ratio for two hexadecimal colors.
 *
 * @param  color1 First color hexadecimal code.
 * @param  color2 Second color hexadecimal code.
 * @return        Contrast ratio.
 */
export const contrast = (color1: string, color2: string): number => {
	const luminance1 = luminance(color1) as number;
	const luminance2 = luminance(color2) as number;
	const brightest = Math.max(luminance1, luminance2);
	const darkest = Math.min(luminance1, luminance2);

	return (brightest + 0.05) / (darkest + 0.05);
};
