/**
 * External dependencies
 */
import React, { FC, ReactNode } from 'react';
import * as importedIcons from 'icons/subscribe';

/**
 * Internal dependencies
 */
import type { Link } from 'types';

type SubscribeLinksProps = {
	links: { [key: string]: Link };
};

const iconComponents: { [key: string]: FC } = {
	...importedIcons,
};

const SubscribeLinks: FC<SubscribeLinksProps> = ({ links }) => {
	const linkComponents: Array<ReactNode> = [];

	const icons: { [key: string]: ReactNode } = {};

	for (const key in iconComponents) {
		const Component = iconComponents[key];
		icons[key] = <Component />;
	}

	for (const key in links) {
		if (!links[key].url || !links[key].label) {
			continue;
		}

		linkComponents.push(
			<li key={key}>
				<a
					href={links[key].url}
					title={links[key].label}
					target="_blank"
					rel="noopener noreferrer"
				>
					{icons.hasOwnProperty(key) && (
						<span className='fbx-icon'>{icons[key]}</span>
					)}
					{links[key].label}
				</a>
			</li>
		);
	}

	return <ul className='fbx-subscribe-links'>{linkComponents}</ul>;
};

export default SubscribeLinks;
