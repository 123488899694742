/**
 * External dependencies
 */
import React, { FC, ReactElement, MouseEvent } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { PopoverButton, DropdownButton } from 'components';
import {
	FacebookIcon,
	LinkedInIcon,
	MailIcon,
	MessengerIcon,
	ShareIcon,
	TwitterIcon,
	WhatsAppIcon,
} from 'icons';
import { withPlayerState, withPlayerStateProps } from 'HOC';
import type { ButtonStyle } from 'components/Button';

type ShareButtonProps = {
	popover?: boolean;
} & withPlayerStateProps;

type Link = {
	url: string;
	mobileUrl?: string;
	icon: ReactElement;
	newWindow?: boolean;
	mobileOnly?: boolean;
};

const isMobile: () => boolean = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);
};

const url = window.location.href;

const links: { [key: string]: Link } = {
	facebook: {
		url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
		icon: <FacebookIcon />,
		newWindow: true,
	},
	twitter: {
		url: `https://twitter.com/intent/tweet?url=${url}`,
		icon: <TwitterIcon />,
	},
	linkedin: {
		url: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=&summary=&source=`,
		icon: <LinkedInIcon />,
		newWindow: true,
	},
	messenger: {
		url: `fb-messenger://share?link=${url}`,
		icon: <MessengerIcon />,
		mobileOnly: true,
	},
	whatsapp: {
		url: `https://wa.me/?text=${url}`,
		mobileUrl: `whatsapp://send?text=${url}`,
		icon: <WhatsAppIcon />,
		// mobileOnly: true,
	},
	mail: {
		url: `mailto:?subject=${document.title}&body=${url}`,
		icon: <MailIcon />,
	},
};

const onClick: (e: MouseEvent<HTMLAnchorElement>) => void = (e) => {
	const link = (
		e.target instanceof HTMLAnchorElement
			? e.target
			: (e.target as HTMLElement).closest('a')
	) as HTMLAnchorElement;

	if ('http' === link.href.substr(0, 4)) {
		e.preventDefault();

		window.open(link.href, link.className, 'width=465,height=500');
	}
};

const ShareButton: FC<ShareButtonProps> = ({ popover }) => {
	const content = (
		<div className='fbx-share-links'>
			{popover && <p className='fbx-title'>Share:</p>}
			<ul>
				{Object.keys(links).map(
					(key) =>
						(!links[key].mobileOnly || !isMobile) && (
							<li key={key}>
								<a
									className={classnames(`fbx-${key}`)} // Used as template literal to allow autoprefixing
									href={
										isMobile() && links[key].mobileUrl
											? links[key].mobileUrl
											: links[key].url
									}
									onClick={
										links[key].newWindow
											? onClick
											: undefined
									}
									target="_blank"
									rel="noopener noreferrer"
								>
									{links[key].icon}
								</a>
							</li>
						)
				)}
			</ul>
		</div>
	);

	const buttonProps = {
		className: 'share-button',
		buttonStyle: 'secondary' as ButtonStyle,
		icon: <ShareIcon />,
	};

	if (true === popover) {
		return <PopoverButton {...buttonProps} popoverContent={content} />;
	} else {
		return (
			<DropdownButton {...buttonProps} dropdownContent={content}>
				Share
			</DropdownButton>
		);
	}
};

export default withPlayerState(ShareButton);
