/**
 * External dependencies
 */
import { filter } from 'lodash';
import classnames from 'classnames';
import React, { FC, MouseEvent } from 'react';

export type Tab = 'featured' | 'all' | 'transcript';

type TabItem = {
	name: Tab;
	label: string;
	visible: boolean;
};

type Tabs = Array<TabItem>;

type PlaylistNavigationProps = {
	allEpisodes: boolean;
	currentTab: Tab;
	featuredEpisodes: boolean;
	onChange: (tab: Tab) => void;
	transcript: boolean;
};

const PlaylistNavigation: FC<PlaylistNavigationProps> = ({
	allEpisodes,
	currentTab,
	featuredEpisodes,
	onChange,
	transcript,
}) => {
	const onClick = (e: MouseEvent, tab: Tab) => {
		e.preventDefault();

		onChange(tab);
	};

	const tabs: Tabs = filter(
		[
			{
				name: 'featured',
				label: 'Featured',
				visible: featuredEpisodes,
			},
			{
				name: 'all',
				label: 'All Episodes',
				visible: allEpisodes,
			},
			{
				name: 'transcript',
				label: 'Transcript',
				visible: transcript,
			},
		],
		(item) => item.visible
	);

	const getItem = (item: TabItem) =>
		tabs.length > 1 ? (
			<button
				key={item.name}
				className={classnames({
					'fbx-is-active': currentTab === item.name,
				})}
				onClick={(e) => onClick(e, item.name)}
			>
				{item.label}
			</button>
		) : (
			<span key={item.name}>{item.label}</span>
		);

	return <ul className='fbx-playlist-navigation'>{tabs.map(getItem)}</ul>;
};

export default PlaylistNavigation;
