/**
 * External dependencies
 */
import React, { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { Button } from 'components';
import { PlayIcon, PauseIcon, SpinnerIcon } from 'icons';
import { useAudioComponent, usePlayerState } from 'context';

type PlayPauseButtonProps = {
	size?: 'large' | 'medium' | 'small' | 'xsmall' | 'tiny';
};

const PlayPauseButton: FC<PlayPauseButtonProps> = ({ size }) => {
	const audioComponent = useAudioComponent();
	const [{ isLoading }] = usePlayerState();

	const isPlaying = audioComponent && !audioComponent.paused;
	const text = isPlaying ? 'Pause' : 'Play';
	const Icon = isLoading ? SpinnerIcon : isPlaying ? PauseIcon : PlayIcon;

	const className = classnames('fbx-play-pause-button', `fbx-is-${size}`, {
		'fbx-is-playing': !isPlaying,
		'fbx-is-loading': isLoading,
	});

	const handlePlayPause = () => {
		if (!audioComponent) {
			return;
		}

		if (isPlaying) {
			audioComponent.pause();
		} else {
			audioComponent.play();
		}
	};

	return (
		<Button className={className} onClick={handlePlayPause} icon={<Icon />}>
			{text}
		</Button>
	);
};

PlayPauseButton.defaultProps = {
	size: 'large',
};

export default PlayPauseButton;
