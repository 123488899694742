/**
 * External dependencies
 */
import React, { FC, ReactNode, useState, useRef } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import type { ButtonProps } from 'components/Button';
import { Button } from 'components';
import { ChevronLargeRightIcon } from 'icons';

type DropdownButtonProps = {
	dropdownContent: ReactNode;
	children?: ReactNode;
} & ButtonProps;

const DropdownButton: FC<DropdownButtonProps> = ({
	children,
	dropdownContent,
	...buttonProps
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState<number>(0);

	buttonProps.className = classnames(
		buttonProps.className,
		'fbx-dropdown-button'
	);
	buttonProps.onClick = () => {
		if (ref.current) {
			if (0 === height) {
				setHeight(ref.current.scrollHeight);
			} else {
				setHeight(0);
			}
		}
	};

	const className = classnames('fbx-dropdown-button', {
		'fbx-is-open': 0 !== height,
	});

	return (
		<div className={className}>
			<Button {...buttonProps}>
				{children}
				<span className='fbx-chevron'>
					<ChevronLargeRightIcon />
				</span>
			</Button>
			<div
				className='fbx-dropdown'
				style={{ height: `${height}px` }}
				ref={ref}
			>
				{dropdownContent}
			</div>
		</div>
	);
};

export default DropdownButton;
