/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { PopoverButton, SubscribeLinks, DropdownButton } from 'components';
import { RSSIcon } from 'icons';
import { withPlayerState, withPlayerStateProps } from 'HOC';
import { useConfig } from 'context';
import type { ButtonProps } from 'components/Button';
import type { Link } from 'types';

type SubscribeButtonProps = {
	links: { [key: string]: Link };
	popover?: boolean;
} & ButtonProps &
	withPlayerStateProps;

const SubscribeButton: FC<SubscribeButtonProps> = ({
	links,
	playerState,
	popover,
	...buttonProps
}) => {
	const config = useConfig();

	const { layout } = playerState;
	const { sticky } = config!;

	if (!buttonProps.buttonStyle) {
		buttonProps.buttonStyle = 'secondary';
	}

	const hasLabel =
		!popover ||
		(sticky
			? 'full' === layout || 'primary' === buttonProps.buttonStyle
			: 'narrow' !== layout || 'primary' === buttonProps.buttonStyle);

	const label = hasLabel ? 'Subscribe' : null;
	const content = <SubscribeLinks links={links} />;

	buttonProps.className = 'subscribe-button';
	buttonProps.icon = <RSSIcon />;

	if (popover) {
		return (
			<PopoverButton {...buttonProps} popoverContent={content}>
				{label}
			</PopoverButton>
		);
	} else {
		return (
			<DropdownButton {...buttonProps} dropdownContent={content}>
				{label}
			</DropdownButton>
		);
	}
};

export default withPlayerState(SubscribeButton);
