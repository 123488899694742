/**
 * External dependencies
 */
import classnames from 'classnames';
import React, { FC, ReactNode, useLayoutEffect, useRef, useState } from 'react';

/**
 * Internal dependencies
 */
import { useAudioComponent } from 'context/audio';
import { useCurrentEpisode } from 'hooks';
import { withPlayerState, withPlayerStateProps } from 'HOC/withPlayerState';
import SpeedButton from 'components/SpeedButton';
import TimeDisplay from 'components/TimeDisplay';
import TimeShiftButton from 'components/TimeShiftButton';
import VolumeControl, { VolumeControlProps } from 'components/VolumeControl';

type ControlsProps = {
	children?: ReactNode;
	controls?: String[];
	showTimeDisplay?: boolean;
} & withPlayerStateProps;

type ControlsState = 'vertical' | 'horizontal';

const Controls: FC<ControlsProps> = ({
	children,
	controls,
	playerState,
	showTimeDisplay,
}) => {
	const audioComponent = useAudioComponent();
	const controlsRef = useRef<HTMLDivElement>(null);
	const volumeControlRef = useRef<HTMLDivElement>(null);
	const currentEpisode = useCurrentEpisode();
	const [orientation, setOrientation] = useState<ControlsState>('horizontal');
	const [direction, setDirection] =
		useState<VolumeControlProps['direction']>('toTop');

	useLayoutEffect(() => {
		if (!controlsRef.current || !volumeControlRef.current) {
			return;
		}

		const controlsElement = controlsRef.current as HTMLDivElement;
		const volumeControlElement = volumeControlRef.current as HTMLDivElement;

		const controlsRect = controlsElement.getBoundingClientRect();
		const volumeControlRect = volumeControlElement.getBoundingClientRect();

		const delta =
			controlsRect.x +
			controlsRect.width -
			volumeControlRect.x -
			volumeControlRect.width;

		const sliderSize = volumeControlElement.classList.contains(
			'is-secondary'
		)
			? 54
			: 50;

		setOrientation(sliderSize > delta ? 'vertical' : 'horizontal');
		setDirection(
			volumeControlRect.top > sliderSize + 10 ? 'toTop' : 'toBottom'
		);
	}, [playerState.playerWidth]);

	const controlsStyle = children ? 'secondary' : 'control';

	const className = classnames('fbx-controls', {
		'fbx-is-alternative': !!children,
	});

	return (
		<div className={className} ref={controlsRef}>
			<div className='fbx-controls-primary'>
				{controls!.includes('speed') && (
					<SpeedButton buttonStyle={controlsStyle} />
				)}
				{controls!.includes('rewind') && (
					<TimeShiftButton direction="<" isAlternative={!!children} />
				)}
				{children}
				{controls!.includes('rewind') && (
					<TimeShiftButton
						deltaSeconds={30}
						isAlternative={!!children}
					/>
				)}
				<VolumeControl
					buttonStyle={controlsStyle}
					orientation={orientation}
					direction={direction}
					ref={volumeControlRef}
				/>
			</div>
			{showTimeDisplay && (
				<div className='fbx-controls-auxiliary'>
					<TimeDisplay
						time={{
							current: audioComponent?.currentTime || 0,
							total:
								audioComponent?.duration ||
								currentEpisode?.duration ||
								0,
						}}
					/>
				</div>
			)}
		</div>
	);
};

Controls.defaultProps = {
	controls: [],
	showTimeDisplay: true,
};

export default withPlayerState(Controls);
