/**
 * External dependencies
 */
import classnames from 'classnames';
import React, { FC, ReactNode, forwardRef } from 'react';

export type ButtonStyle = 'primary' | 'secondary' | 'control' | 'fbx-none';

type ButtonProps = {
	buttonStyle?: ButtonStyle;
	children?: ReactNode;
	className?: string;
	icon?: ReactNode;
	iconPosition?: 'left' | 'right';
	isActive?: boolean;
	label?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	size?: 'normal' | 'small';
	target?: string;
	url?: string;
};

const Button: FC<ButtonProps> = forwardRef(
	(
		{
			buttonStyle,
			children,
			className,
			icon,
			iconPosition,
			isActive = false,
			label,
			onClick,
			size,
			target,
			url,
		},
		ref
	) => {
		const classNames = classnames('fbx-button', `fbx-is-size-${size}`, className, {
			'fbx-has-icon': !!icon,
			'fbx-has-label': !!children,
			'fbx-is-active': isActive,
			[`fbx-is-${buttonStyle}`]: 'none' !== buttonStyle,
		});

		const wrappedIcon = icon ? <span className='fbx-icon'>{icon}</span> : null;

		const ButtonTag = url ? 'a' : 'button';

		const buttonProps: { [key: string]: any } = {
			'aria-label': label,
			className: classNames,
			ref,
		};

		if (url) {
			buttonProps.href = url;

			if (target) {
				buttonProps.target = target;
			}
		} else {
			buttonProps.onClick = onClick;
		}

		const content = children ? (
			<>
				{'left' === iconPosition && wrappedIcon}
				<span>{children}</span>
				{'right' === iconPosition && wrappedIcon}
			</>
		) : (
			wrappedIcon
		);

		return <ButtonTag {...buttonProps}>{content}</ButtonTag>;
	}
);

Button.defaultProps = {
	onClick: () => {},
	buttonStyle: 'primary',
	iconPosition: 'left',
	size: 'normal',
};

export default Button;

export type { ButtonProps };
