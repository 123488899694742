/**
 * External dependencies
 */
import React, { FC } from 'react';
import { filter, reverse, trim, map } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import { withConfig, withConfigProps } from 'HOC/withConfig';
import { withPlayerState, withPlayerStateProps } from 'HOC/withPlayerState';
import PlaylistItem from 'components/PlaylistItem';
import Scrollable from 'components/Scrollable';
import type { Track } from 'types';

type EnhancedTrack = {
	foundInDescription?: boolean;
} & Track;

type PlaylistItemsProps = {
	allowTwoColumns?: boolean;
	search: string;
	shouldReverse: boolean;
	showFeatured: boolean;
} & withConfigProps &
	withPlayerStateProps;

const PlaylistItems: FC<PlaylistItemsProps> = ({
	allowTwoColumns = true,
	config,
	playerState: { playerWidth },
	search,
	shouldReverse,
	showFeatured,
}) => {
	if (!config) {
		return null;
	}

	let tracks = [...config.tracks] as Array<EnhancedTrack>;

	if (showFeatured) {
		tracks = filter(config.tracks, (item) => !!item.featured);
	}

	if (search) {
		const trimmedSearch = trim(search);

		if (trimmedSearch) {
			const pattern = new RegExp(trimmedSearch, 'gi');

			tracks = map(
				filter(
					tracks,
					(item) =>
						pattern.test(item.title) ||
						(!!item.description && pattern.test(item.description))
				),
				(item) => ({
					...item,
					description: item.description
						? item.description.replaceAll(pattern, `<em>$&</em>`)
						: undefined,
					title: item.title.replaceAll(pattern, `<em>$&</em>`),
				})
			);
		}
	}

	shouldReverse && reverse(tracks);

	const className = classnames('fbx-playlist-items', {
		'fbx-two-columns': allowTwoColumns && playerWidth && playerWidth > 1000,
	});

	const getItems = (update: () => void) =>
		tracks.map((track) => (
			<PlaylistItem
				key={track.episodeNo}
				track={track}
				onToggle={update}
			/>
		));

	return (
		<Scrollable className={className}>
			{(update) =>
				tracks.length ? (
					getItems(update)
				) : (
					<div className='fbx-no-results'>
						<h3>No results found.</h3>
						<p>
							Oops! We couldn't find any episodes that match your
							search term.
						</p>
					</div>
				)
			}
		</Scrollable>
	);
};

export default withPlayerState(withConfig(PlaylistItems));
