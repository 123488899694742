export const scrollParentToChild = (
	parent: HTMLElement,
	child: HTMLElement
) => {
	const parentRect = parent.getBoundingClientRect();
	const childRect = child.getBoundingClientRect();

	const parentViewableArea = {
		height: parent.clientHeight,
		width: parent.clientWidth,
	};

	const isViewable =
		childRect.top >= parentRect.top &&
		childRect.bottom <= parentRect.top + parentViewableArea.height;

	if (!isViewable) {
		const scrollTop = childRect.top - parentRect.top;
		const scrollBot = childRect.bottom - parentRect.bottom;

		if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
			parent.scrollTop += scrollTop;
		} else {
			parent.scrollTop += scrollBot;
		}
	}
};
