/**
 * External dependencies
 */
import { difference, pickBy } from 'lodash';

/**
 * Internal dependencies
 */
import type { PlayerConfig, PartialPlayerConfig } from 'interfaces';
import type { RequestParams, PlayerType } from 'types';
import { buildApiUrl, apiCall } from 'utils/api';

type params = {
	[key: string]: any;
};

export const buildConfigApiUrl = (
	type: PlayerType,
	requestParams: RequestParams,
	params: params
): string => {
	let path = `players/${type}/`;

	if (requestParams.hash) {
		path += `${requestParams.hash}/`;

		if ('track' === type) {
			path += `${requestParams.track}/`;
		}
	} else {
		// GUID as track.
		params.guid = requestParams.track;
	}

	return buildApiUrl(path, params);
};

export const loadPlayerConfig = async (
	type: PlayerType,
	requestParams: RequestParams,
	initialParams?: object
): Promise<PlayerConfig | false> =>
	await apiCall(
		buildConfigApiUrl(type, requestParams, {
			...(initialParams || {}),
		})
	);

export const isValidConfig = (
	config: PartialPlayerConfig
): config is PlayerConfig => {
	// List all mandatory config fields.
	const keys = [
		'buttonConfig',
		'buttons',
		'color',
		'controls',
		'cover',
		'coverDisplay',
		'credits',
		'id',
		'position',
		'sticky',
		'style',
		'title',
		'tracks',
	];

	const optionalKeys = ['isStandalone', 'playlist', 'proxied'];

	// Add optional fields if they exist on config object fields.
	for (const field of optionalKeys) {
		if (config.hasOwnProperty(field)) {
			keys.push(field);
		}
	}

	// Remove redundant fields.
	config = pickBy(
		config,
		(v, k) =>
			!['createdAt', 'updatedAt', 'hash', 'name', 'showHash'].includes(k)
	) as PartialPlayerConfig;

	const configKeys = Object.keys(config);

	const isInvalid =
		configKeys.length !== keys.length ||
		keys.some((v) => !configKeys.includes(v));

	if (isInvalid) {
		const keysToString = (keys: Array<string>) =>
			keys.map((p) => `'${p}'`).join(', ');

		const missingKeys = difference(keys, configKeys);
		const extraKeys = difference(configKeys, keys);

		const errorArray = [
			'Fusebox Player: provided config is invalid.',
			...(missingKeys.length
				? [
						`The following properties are missing: ${keysToString(
							missingKeys
						)}`,
				  ]
				: []),
			...(extraKeys.length
				? [
						`The following extra properties exist: ${keysToString(
							extraKeys
						)}`,
				  ]
				: []),
		];

		console.log(errorArray.join(' '));
	}

	return !isInvalid;
};
