/**
 * External dependencies
 */
import React, {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';

/**
 * Internal dependencies
 */
import { addReceiver } from 'utils/broadcast';
import { isAllowedHost } from 'utils/api';
import { getPlayerAPI, loadApi, logExtendedAPIUsage } from 'api';
import { isValidConfig, loadPlayerConfig } from 'utils/config';
import type {
	IObjectKeys,
	PartialPlayerConfig,
	PlayerConfig,
} from 'interfaces';
import type { PlayerType, RequestParams } from 'types';

type ConfigProviderProps = {
	children: ReactNode;
	configId: string;
	initialConfig?: PlayerConfig;
	params: IObjectKeys;
	requestParams: RequestParams;
	type: PlayerType;
	wrapper: HTMLDivElement;
};

type ConfigConsumerProps = {
	children: (config: PlayerConfig) => ReactNode;
};

const ConfigContext = createContext<PlayerConfig | undefined>(undefined);
const SetConfigContext = createContext<
	Dispatch<SetStateAction<PlayerConfig | undefined>> | undefined
>(undefined);

const ConfigProvider: FC<ConfigProviderProps> = ({
	children,
	configId,
	initialConfig,
	params,
	requestParams,
	type,
	wrapper,
}) => {
	const [config, setConfig] = useState<PlayerConfig | undefined>(
		initialConfig
	);

	useEffect(() => {
		if (config) {
			return;
		}

		(async () => {
			const [configPromiseResult, apiPromiseResult] =
				await Promise.allSettled([
					'custom' !== type &&
						loadPlayerConfig(type, requestParams, params),
					loadApi(),
				]);

			if (
				'fulfilled' === configPromiseResult.status &&
				(configPromiseResult.value ||
					('custom' === type &&
						'fulfilled' === apiPromiseResult.status &&
						apiPromiseResult.value))
			) {
				let config: PartialPlayerConfig = {
					...(configPromiseResult.value || { credits: true }),
					id: configId,
				};

				const PlayerAPI = getPlayerAPI();

				if (PlayerAPI) {
					config = PlayerAPI.init(config, setConfig);
				}

				if (!isValidConfig(config)) {
					return;
				}

				if (config.sticky && !window.FuseboxPlayerPreview) {
					document.body.appendChild(wrapper);
				}

				if (
					PlayerAPI &&
					PlayerAPI.isExtendedAPI() &&
					!isAllowedHost()
				) {
					// Log extended API usage.
					logExtendedAPIUsage(config);
				}

				setConfig(config);
			}
		})();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	addReceiver('config', (newConfig) =>
		setConfig({
			...config,
			...newConfig,
		})
	);

	return (
		<ConfigContext.Provider value={config}>
			<SetConfigContext.Provider value={setConfig}>
				{config && children}
			</SetConfigContext.Provider>
		</ConfigContext.Provider>
	);
};

const ConfigConsumer: FC<ConfigConsumerProps> = ({ children }) => {
	return (
		<ConfigContext.Consumer>
			{(config) => config && children(config)}
		</ConfigContext.Consumer>
	);
};

const useConfig = () => useContext(ConfigContext);
const useSetConfig = () => useContext(SetConfigContext);

export {
	ConfigConsumer,
	ConfigContext,
	ConfigProvider,
	useConfig,
	useSetConfig,
};
