/**
 * Internal dependencies
 */
import type { PlayerState } from './types';

const defaultState: PlayerState = {
	currentEpisode: null,
	currentTime: 0,
	disabled: false,
	initialized: false,
	isLoading: false,
	isPlaying: false,
	isPlaylistVisible: true,
	theme: null,
};

export default defaultState;
