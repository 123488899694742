/**
 * Internal dependencies
 */
import 'styles/main.scss';
import {
	renderPlayer,
	initBroadcast,
	addReceiver,
	postMessage,
	getBaseUrl,
} from 'utils';

const currentScript = document.currentScript as HTMLScriptElement;

if (currentScript) {
	if (window.opener && getBaseUrl().split('//')[1] === window.location.host) {
		const { hash } = currentScript.dataset;

		if (hash) {
			initBroadcast(window.opener, hash);

			document.body.classList.add('fbx-standalone-player');
			document.documentElement.style.height = '100%';

			addReceiver('init', ({ config, state }) => {
				renderPlayer(currentScript, config, state);
			});

			window.addEventListener('beforeunload', () => {
				postMessage('close');
			});

			postMessage('ready');
		}
	} else {
		renderPlayer(currentScript);
	}
}
