/**
 * External dependencies
 */
import classnames from 'classnames';
import React, { FC, useState, useRef, useEffect } from 'react';

/**
 * Internal dependencies
 */
import { ChevronUpIcon } from 'icons';
import { useConfig, usePlayerState } from 'context';
import ArchiveButton from 'components/ArchiveButton';
import Button from 'components/Button';
import Buttons from 'components/Buttons';
import Controls from 'components/Controls';
import PlayPauseButton from 'components/PlayPauseButton';
import PodcastImage from 'components/PodcastImage';
import PodcastTitle from 'components/PodcastTitle';
import ProgressBar from 'components/ProgressBar';
import VolumeControl from 'components/VolumeControl';

type PlayerLayoutProps = {
	setRef?: (ref: HTMLDivElement) => void;
};

const PlayerLayout: FC<PlayerLayoutProps> = ({ setRef }) => {
	const [playerState] = usePlayerState();
	const config = useConfig();
	const [collapsed, setCollapsed] = useState<boolean>(true);
	const [transition, setTransition] = useState<boolean>(false);
	const [hidden, setHidden] = useState<boolean>(false);

	const ref = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const { currentEpisode, layout } = playerState;

	useEffect(() => {
		if (ref.current && setRef) {
			setRef(ref.current);
		}
	}, [ref, setRef]);

	useEffect(() => {
		if (
			true === hidden &&
			true === transition &&
			contentRef.current &&
			ref.current
		) {
			const contentElement = contentRef.current;

			setTransition(true);

			ref.current.style.height = `${ref.current.offsetHeight}px`;

			const transitionend = (e: TransitionEvent) => {
				if (
					e.target &&
					(e.target as HTMLElement).isSameNode(contentElement)
				) {
					setCollapsed(!collapsed);
					setHidden(false);
					contentElement.removeEventListener(
						'transitionend',
						transitionend
					);
				}
			};

			contentElement.addEventListener('transitionend', transitionend);

			return () =>
				contentElement.removeEventListener(
					'transitionend',
					transitionend
				);
		}
	}, [hidden, transition, collapsed]);

	useEffect(() => {
		if (true === transition && ref.current) {
			const element = ref.current;

			if (collapsed) {
				element.style.height = '';
			} else {
				element.style.height = `${element.scrollHeight}px`;
			}

			const transitionend = (e: TransitionEvent) => {
				if (e.target && (e.target as HTMLElement).isSameNode(element)) {
					if (!collapsed) {
						element.style.height = '';
					}

					setTransition(false);

					element.removeEventListener('transitionend', transitionend);
				}
			};

			element.addEventListener('transitionend', transitionend);

			return () =>
				element.removeEventListener('transitionend', transitionend);
		}
	}, [collapsed]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!config) {
		return null;
	}

	const {
		buttons,
		controls,
		cover,
		coverDisplay,
		position,
		sticky,
		style,
		title,
	} = config;

	const hasButtons = !!Object.keys(buttons).length;
	const isLargeStyle = 'large' === style || 'narrow' === layout;
	const isCollapsable = sticky && 'narrow' === layout;
	const isCollapsed = isCollapsable && collapsed;
	const playPauseInControls = !isCollapsed && (sticky || isLargeStyle);
	const isProgressBarThick = playPauseInControls;
	const isCompact = false; // !hasButtons && !controls.length;
	const showProgressBarTimeDisplay = playPauseInControls || isCompact;
	const isTitleSingleLine = !sticky && 'narrow' !== layout;

	const titlePosition =
		sticky && 'narrow' !== layout
			? 'with-content'
			: 'large' === style
			? 'top'
			: 'medium' === layout
			? 'above-controls'
			: 'narrow' === layout
			? 'with-cover'
			: 'with-controls';

	const playPauseButtonSize = isCollapsed
		? 'tiny'
		: sticky && 'narrow' !== layout
		? 'xsmall'
		: isLargeStyle
		? 'medium'
		: 'medium' === layout
		? 'small'
		: 'large';

	const imageSize = isCollapsed
		? 'xsmall'
		: 'large' === style && 'narrow' !== layout
		? 'xlarge'
		: 'large' === style
		? 'large'
		: 'narrow' === layout
		? 'small'
		: 'medium';

	const titleAlign =
		('large' === style &&
			('narrow' === layout || (!hasButtons && !cover))) ||
		('regular' === style && !cover)
			? 'center'
			: 'left';

	const playPauseButton = <PlayPauseButton size={playPauseButtonSize} />;

	const clamp = isCollapsed ? 2 : 3;

	const titleElement = (
		<PodcastTitle
			title={currentEpisode?.title || '>>episode title<<'}
			singleLine={isTitleSingleLine}
			showTitle={'large' === style ? title : undefined}
			align={titleAlign}
			clamp={clamp}
		/>
	);

	/**
	 * `prettier/prettier` rule needs to be disabled here. If the class name is not quoted, it doesn't get prefixed,
	 * which breaks the animation.
	 */
	const className = classnames('fbx-player-layout', {
		'fbx-is-compact': isCompact,
		'fbx-is-collapsed': isCollapsed,
		'fbx-content-hidden': hidden,
		'fbx-transition': transition, // eslint-disable-line prettier/prettier
	});

	const toggle = () => {
		setTransition(true);
		setHidden(true);
	};

	const getCollapseButton = () => (
		<Button
			buttonStyle="secondary"
			className='fbx-collapse-button'
			icon={<ChevronUpIcon />}
			onClick={toggle}
		/>
	);

	return (
		<div className={className} ref={ref}>
			{isCollapsable &&
				!isCollapsed &&
				position === 'bottom' &&
				getCollapseButton()}
			{coverDisplay && cover ? (
				<div className='fbx-cover-wrap'>
					<PodcastImage
						src={currentEpisode?.artwork || cover.imageUrl}
						alt={title}
						url={cover.link ? cover.url : undefined}
						size={imageSize}
					/>
					{'with-cover' === titlePosition && titleElement}
				</div>
			) : (
				'with-cover' === titlePosition && titleElement
			)}
			<div className='fbx-player-content' ref={contentRef}>
				{'top' === titlePosition && titleElement}
				<div className='fbx-content-wrap'>
					{'with-content' === titlePosition && titleElement}
					<div className='fbx-controls-wrap'>
						{'above-controls' === titlePosition && titleElement}
						{!playPauseInControls && (
							<div className='fbx-play-pause-button-wrapper'>
								{playPauseButton}
							</div>
						)}
						{isCollapsed ? (
							<>
								<VolumeControl
									buttonStyle="secondary"
									orientation="vertical"
									direction={
										isCollapsed ? 'toBottom' : 'toTop'
									}
								/>
								<ArchiveButton />
								<Button
									icon={<ChevronUpIcon />}
									className='fbx-expand-button'
									buttonStyle="secondary"
									onClick={toggle}
								/>
							</>
						) : (
							<div className='fbx-controls-inner'>
								{'with-controls' === titlePosition &&
									titleElement}
								{!sticky && (
									<ProgressBar
										showTimeDisplay={
											showProgressBarTimeDisplay
										}
										isThick={isProgressBarThick}
									/>
								)}
								<Controls
									showTimeDisplay={
										!showProgressBarTimeDisplay
									}
									controls={controls}
								>
									{playPauseInControls && playPauseButton}
								</Controls>
								{sticky && (
									<ProgressBar
										showTimeDisplay={
											showProgressBarTimeDisplay
										}
										isThick={isProgressBarThick}
									/>
								)}
							</div>
						)}
					</div>
					{hasButtons && !isCollapsed && (
						<div className='fbx-buttons-wrap'>
							<Buttons />
						</div>
					)}
				</div>
			</div>
			{isCollapsable &&
				!isCollapsed &&
				position === 'top' &&
				getCollapseButton()}
		</div>
	);
};

export default PlayerLayout;
