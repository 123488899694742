/**
 * External dependencies
 */
import React, { createContext, FC, ReactNode, useContext } from 'react';

/**
 * Internal dependencies
 */
import { Audio } from 'components';

type AudioComponentProviderProps = {
	children: ReactNode;
	value?: Audio;
};

type AudioComponentConsumerProps = {
	children: (audioComponent?: Audio) => ReactNode;
};

const AudioComponentContext = createContext<Audio | undefined>(undefined);

const AudioComponentProvider: FC<AudioComponentProviderProps> = ({
	children,
	value,
}) => {
	return (
		<AudioComponentContext.Provider value={value}>
			{children}
		</AudioComponentContext.Provider>
	);
};

const AudioComponentConsumer: FC<AudioComponentConsumerProps> = ({
	children,
}) => {
	return (
		<AudioComponentContext.Consumer>
			{(audio) => children(audio)}
		</AudioComponentContext.Consumer>
	);
};

const useAudioComponent = () => {
	return useContext(AudioComponentContext);
};

export {
	AudioComponentConsumer,
	AudioComponentContext,
	AudioComponentProvider,
	useAudioComponent,
};
