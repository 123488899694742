export const formatTime = (seconds: number): string => {
	if (isNaN(seconds)) {
		// FIX for chrome.
		seconds = 0;
	}

	let time: string =
		new Date(1000 * seconds)
			.toISOString()
			.substr(11, 8)
			.replace(/^[0:]+/, '') || '0';

	if (0 === seconds) {
		time = '00';
	} else if (10 > seconds) {
		time = `0${time}`;
	}

	if (60 >= seconds) {
		time = `0:${time}`;
	}

	return time;
};
