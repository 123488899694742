/**
 * External dependencies
 */
import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import type { Placement } from '@popperjs/core/lib';

/**
 * Internal dependencies
 */
import { Button, Popover } from 'components';
import type { ButtonProps } from 'components/Button';
import type { PopoverOpenEvent } from 'components/Popover';

type PopoverButtonProps = {
	popoverClassName?: string;
	popoverContent: ReactNode;
	popoverOpenEvent?: PopoverOpenEvent;
	popoverPlacement?: Placement;
	popoverRef?: (ref: HTMLDivElement) => void;
};

const PopoverButton: FC<PopoverButtonProps & ButtonProps> = (props) => {
	const {
		popoverClassName,
		popoverContent,
		popoverOpenEvent,
		popoverPlacement,
		popoverRef,
		...buttonProps
	} = props;

	buttonProps.className = classnames(buttonProps.className, 'fbx-popover-button');

	return (
		<Popover
			content={popoverContent}
			openEvent={popoverOpenEvent}
			className={popoverClassName}
			placement={popoverPlacement}
			refCallback={popoverRef}
		>
			<Button {...buttonProps} />
		</Popover>
	);
};

export default PopoverButton;
