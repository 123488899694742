/**
 * External dependencies
 */
import React, { FC, ReactNode } from 'react';

/**
 * Internal dependencies
 */
import { PopoverButton } from 'components';
import { EllipsisIcon } from 'icons';
import { withPlayerState, withPlayerStateProps } from 'HOC';
import type { ButtonProps } from 'components/Button';

type MenuButtonProps = {
	children?: ReactNode;
} & ButtonProps &
	withPlayerStateProps;

const MenuButton: FC<MenuButtonProps> = ({
	children,
	playerState,
	...buttonProps
}) => {
	if (!buttonProps.buttonStyle) {
		buttonProps.buttonStyle = 'secondary';
	}

	return (
		<PopoverButton
			{...buttonProps}
			icon={<EllipsisIcon />}
			popoverContent={children}
			popoverClassName='fbx-fusebox-menu-popover'
		/>
	);
};

export default withPlayerState(MenuButton);
