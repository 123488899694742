/**
 * External dependencies
 */
import classnames from 'classnames';
import React, { FC, useRef, useState } from 'react';

/**
 * Internal dependencies
 */
import { Button } from 'components';
import { SearchIcon, TimesCircleIcon, TimesIcon } from 'icons';

type SearchFormProps = {
	isFloating?: boolean;
	onChange: (value: string) => void;
	onToggle?: (open: boolean) => void;
	placeholder?: string;
	value: string;
};

const SearchForm: FC<SearchFormProps> = ({
	isFloating,
	onChange,
	onToggle,
	placeholder = 'Search episode...',
	value,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [open, setOpen] = useState<boolean>(false);

	const handleReset = () => {
		inputRef.current!.focus();

		onChange('');
	};

	const className = classnames('fbx-search-form', {
		'fbx-is-floating': !!isFloating,
		'fbx-is-open': open,
	});

	const handleToggle = () => {
		setOpen(!open);
		onToggle && onToggle(!open);
	};

	return (
		<>
			{isFloating && (
				<Button
					onClick={handleToggle}
					className='fbx-search-form-toggle'
					buttonStyle="secondary"
					icon={<SearchIcon />}
				/>
			)}
			<div className={className}>
				<div className='fbx-search-form-field'>
					<span className='fbx-search-icon'>
						<SearchIcon role="presentation" />
					</span>
					<input
						ref={inputRef}
						type="search"
						className='fbx-search-input'
						placeholder={placeholder}
						value={value}
						onChange={(e) => onChange(e.target.value)}
					/>
					{value && (
						<button className='fbx-search-clear' onClick={handleReset}>
							<TimesCircleIcon role="presentation" />
							Reset
						</button>
					)}
				</div>
				{isFloating && (
					<Button
						onClick={handleToggle}
						className='fbx-search-form-close'
						buttonStyle="secondary"
						icon={<TimesIcon />}
					/>
				)}
			</div>
		</>
	);
};

export default SearchForm;
