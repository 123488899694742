/**
 * External dependencies
 */
import { intersection, pickBy } from 'lodash';

/**
 * Internal dependencies
 */
import { Buttons, Controls, Track } from 'types';
import { Capabilities } from './capabilities';
import { isValidConfig } from 'utils/config';
import { Link, PlaylistFeature } from 'types';
import { pickByKey } from 'utils/collection';
import { postMessage } from 'utils/broadcast';
import { removePlayer } from 'utils/removePlayer';
import Audio from 'components/Audio';
import type {
	ButtonConfig,
	Cover,
	PartialPlayerConfig,
	PlayerConfig,
} from 'interfaces';
import type { Playlist } from 'interfaces';

interface ExtendedPlayerAPI {
	setTitle: (title: string) => void;
	setTracks: (tracks: Array<Track>) => void;
}

export interface PublicPlayerAPI extends Partial<ExtendedPlayerAPI> {
	getConfig(): PartialPlayerConfig | undefined;
	getCurrentTime(): number;
	getDuration(): number;
	getVolume(): number;
	isLoaded(): boolean;
	isPaused(): boolean;
	pause(): void;
	play(): void;
	remove(): void;
	setButtonConfig(config: ButtonConfig): void;
	setButtons(buttons: Array<string>): void;
	setColor(color: string): void;
	setConfig(config: { [key: string]: any }): void;
	setControls(controls: Array<string>): void;
	setCover(cover: { [key: string]: any }): void;
	setCoverDisplay(display: boolean): void;
	setCredits(credits: boolean): void;
	setCta(cta: false | Link): void;
	setCurrentTime(time: number): void;
	setMuted(muted: boolean): void;
	setPlaylist(playlist: Playlist): void;
	setPlaylistColor(color: 'light' | 'dark'): void;
	setPlaylistDisplayFeaturedEpisodes(display: boolean): void;
	setPlaylistFeatures(features: Array<PlaylistFeature>): void;
	setPlaylistOrder(order: 'asc' | 'desc'): void;
	setPosition(position: 'top' | 'bottom'): void;
	setSticky(sticky: boolean): void;
	setStyle(style: string): void;
	setSubscribeLinks(links: { [key: string]: Link }): void;
	setVolume(volume: number): void;
}

type SetConfig = (config: PlayerConfig) => void;

window.FuseboxPlayerAPIInstances = {};

const extendedAPI: ExtendedPlayerAPI = {
	/**
	 * Set podcast title.
	 *
	 * @param  title Podcast title.
	 */
	setTitle(title: string) {
		if (typeof title !== 'string') {
			return;
		}

		const instance = this as unknown as PlayerAPI;

		instance.config = {
			...instance.config!,
			title,
		};

		instance.update();
	},

	/**
	 * Sets tracks list.
	 *
	 * @param  tracks Array of tracks.
	 */
	setTracks(tracks: Array<Track>) {
		if (!Array.isArray(tracks)) {
			return;
		}

		const instance = this as unknown as PlayerAPI;

		const preparedTracks: Array<Track> = [];

		const requiredTrackFields = {
			title: 'string',
		};

		const optionalTrackFields = {
			artwork: 'string',
			description: 'string',
			duration: 'number',
			episodeNo: 'number',
			featured: 'boolean',
			file: 'string',
			transcript: 'string',
		};

		const trackFields = {
			...requiredTrackFields,
			...optionalTrackFields,
		};

		const requiredTrackKeys = Object.keys(requiredTrackFields);
		const optionalTrackKeys = Object.keys(optionalTrackFields);
		const trackKeys = [...requiredTrackKeys, ...optionalTrackKeys];

		for (let track of tracks) {
			if (typeof track !== 'object') {
				continue;
			}

			track = pickBy(
				track,
				(v, k) => v !== null && trackKeys.includes(k)
			) as Track;

			const requiredKeys = Object.keys(track).filter((key) =>
				requiredTrackKeys.includes(key)
			);

			if (requiredKeys.length < requiredTrackKeys.length) {
				continue;
			}

			const valid = Object.entries(track).reduce(
				(acc, [key, value]) =>
					typeof value ===
					trackFields[key as keyof typeof trackFields]
						? acc
						: false,
				true
			);

			if (valid) {
				preparedTracks.push(track);
			}
		}

		instance.config = {
			...instance.config!,
			tracks: preparedTracks,
		};

		instance.update();
	},
};

/**
 * PlayerAPI class
 *
 * @param apiKey API key.
 */
export class PlayerAPI implements PublicPlayerAPI {
	_isLoaded: boolean = false;
	audioComponent?: Audio;
	config?: PartialPlayerConfig;
	id?: string;
	setConfigCallback?: SetConfig;
	setTitle?: ExtendedPlayerAPI['setTitle'];
	setTracks?: ExtendedPlayerAPI['setTracks'];
	shouldUpdate: boolean = true;
	capabilities: Capabilities;

	constructor(capabilities: Capabilities) {
		this.capabilities = capabilities;
	}

	static getInstance(id: string) {
		return window.FuseboxPlayerAPIInstances![id];
	}

	/**
	 * Initialize API with player id
	 *
	 * @param id Player ID.
	 */
	init(
		config: PartialPlayerConfig,
		setConfig: SetConfig
	): PartialPlayerConfig {
		this.id = config.id;
		this.config = config;
		this.setConfigCallback = setConfig;

		if (this.isExtendedAPI()) {
			this.setTitle = extendedAPI.setTitle.bind(this);
			this.setTracks = extendedAPI.setTracks.bind(this);
		}

		window.FuseboxPlayerAPIInstances![this.config.id] = {
			getConfig: this.getConfig.bind(this),
			getCurrentTime: this.getCurrentTime.bind(this),
			getDuration: this.getDuration.bind(this),
			getVolume: this.getVolume.bind(this),
			isLoaded: this.isLoaded.bind(this),
			isPaused: this.isPaused.bind(this),
			pause: this.pause.bind(this),
			play: this.play.bind(this),
			remove: this.remove.bind(this),
			setButtonConfig: this.setButtonConfig.bind(this),
			setButtons: this.setButtons.bind(this),
			setColor: this.setColor.bind(this),
			setConfig: this.setConfig.bind(this),
			setControls: this.setControls.bind(this),
			setCover: this.setCover.bind(this),
			setCoverDisplay: this.setCoverDisplay.bind(this),
			setCredits: this.setCredits.bind(this),
			setCta: this.setCta.bind(this),
			setCurrentTime: this.setCurrentTime.bind(this),
			setMuted: this.setMuted.bind(this),
			setPlaylist: this.setPlaylist.bind(this),
			setPlaylistColor: this.setPlaylistColor.bind(this),
			setPlaylistDisplayFeaturedEpisodes:
				this.setPlaylistDisplayFeaturedEpisodes.bind(this),
			setPlaylistFeatures: this.setPlaylistFeatures.bind(this),
			setPlaylistOrder: this.setPlaylistOrder.bind(this),
			setPosition: this.setPosition.bind(this),
			setSticky: this.setSticky.bind(this),
			setStyle: this.setStyle.bind(this),
			setSubscribeLinks: this.setSubscribeLinks.bind(this),
			setVolume: this.setVolume.bind(this),
			...(this.isExtendedAPI() && {
				setTitle: this.setTitle,
				setTracks: this.setTracks,
			}),
		};

		this.shouldUpdate = false;

		const event = new CustomEvent('loadFuseboxPlayer', { detail: this.id });
		window.dispatchEvent(event);

		this.shouldUpdate = true;

		return this.config;
	}

	/**
	 * Returns current player config.
	 *
	 * @return Config object.
	 */
	getConfig() {
		return this.config;
	}

	/**
	 * Returns current playback time.
	 *
	 * @return Playback time.
	 */
	getCurrentTime() {
		if (!this.audioComponent) {
			return 0;
		}

		return this.audioComponent.currentTime;
	}

	/**
	 * Returns current episode duration.
	 *
	 * @return Audio duration.
	 */
	getDuration() {
		if (!this.audioComponent) {
			return 0;
		}

		return this.audioComponent.duration;
	}

	/**
	 * Returns object containing all publiuc API methods.
	 *
	 * @return Publically exposed API methods.
	 */
	getPublicAPI(): PublicPlayerAPI | void {
		if (this.id && window.FuseboxPlayerAPIInstances![this.id]) {
			return window.FuseboxPlayerAPIInstances![this.id];
		}
	}

	/**
	 * Returns current player volume.
	 *
	 * @return numeric volume value.
	 */
	getVolume() {
		if (!this.audioComponent) {
			return 0;
		}

		return this.audioComponent.volume;
	}

	/**
	 * Checks if should load extended API.
	 *
	 * @return Whether the API is extended.
	 */
	isExtendedAPI(): boolean {
		return this.capabilities.includes('extended');
	}

	/**
	 * Returns true if player has been fully loaded, false otherwise. This method
	 * will only return true after the player has fully rendered and audio
	 * component has been set up so that all the playback methods are available.
	 *
	 * @return Whether the player has been loaded.
	 */
	isLoaded() {
		return this._isLoaded;
	}

	/**
	 * Determines if the player is paused.
	 *
	 * @return True if paused, false otherwise.
	 */
	isPaused() {
		if (!this.audioComponent) {
			return true;
		}

		return this.audioComponent.paused;
	}

	/**
	 * Pauses the player.
	 */
	pause() {
		if (!this.audioComponent) {
			return;
		}

		this.audioComponent.pause();
	}

	/**
	 * Starts playing the current episode.
	 */
	play() {
		if (!this.audioComponent) {
			return;
		}

		this.audioComponent.play();
	}

	/**
	 * Removes the player by unmounting it from the DOM and destroying the player
	 * API methods related to this instance.
	 */
	remove() {
		removePlayer();

		if (this.id && window.FuseboxPlayerAPIInstances![this.id]) {
			delete window.FuseboxPlayerAPIInstances![this.id];
		}
	}

	setAudioComponent(component: Audio): void {
		this.audioComponent = component;

		this._isLoaded = true;

		const event = new CustomEvent('fuseboxPlayerLoaded', {
			detail: this.id,
		});
		window.dispatchEvent(event);
	}

	/**
	 * Sets button config for CTA and/or subscribe buttons.
	 *
	 * @param  config Button config object.
	 */
	setButtonConfig(config: ButtonConfig) {
		if (!config || 'object' !== typeof config) {
			return;
		}

		this.config = {
			...this.config!,
			buttonConfig: {},
		};

		config = pickByKey(config, ['cta', 'subscribe']);

		config.cta && this.setCta(config.cta);
		config.subscribe && this.setSubscribeLinks(config.subscribe);

		this.update();
	}

	/**
	 * Sets active buttons.
	 *
	 * @param  buttons Array containing active buttons names.
	 */
	setButtons(buttons: Array<string>) {
		if (!Array.isArray(buttons)) {
			return;
		}

		this.config = {
			...this.config!,
			buttons: intersection(buttons, [
				'cta',
				'subscribe',
				'share',
				'download',
			]) as Buttons,
		};

		this.update();
	}

	/**
	 * Sets player color.
	 *
	 * @param  color Player colod in hexadecimal format (e.g. #f6719e)
	 */
	setColor(color: string) {
		if (
			'string' !== typeof color ||
			!/^#([0-9A-F]{3}){1,2}$/i.test(color)
		) {
			return;
		}

		this.config = {
			...this.config!,
			color,
		};

		this.update();
	}

	/**
	 * Sets player config. This method accepts an object containing any of the
	 * config keys. This method calls corresponding methods for each entry to
	 * apply validation for each value.
	 *
	 * @param  config Object containing some of the config properties.
	 */
	setConfig(config: { [key: string]: any }) {
		if (!this.config) {
			return;
		}

		const prevShouldUpdate = this.shouldUpdate;

		this.shouldUpdate = false;

		for (const key in config) {
			const method = `set${key[0].toUpperCase() + key.substring(1)}`;

			if (typeof this[method as keyof PlayerAPI] === 'function') {
				(this[method as keyof PlayerAPI] as Function)(config[key]);
			}
		}

		this.shouldUpdate = prevShouldUpdate;
		this.update();
	}

	/**
	 * Sets active controls.
	 *
	 * @param  controls Cntrols array. Possible values are `speed` and `rewind`.
	 */
	setControls(controls: Array<string>) {
		if (!Array.isArray(controls)) {
			return;
		}

		this.config = {
			...this.config!,
			controls: intersection(controls, ['speed', 'rewind']) as Controls,
		};

		this.update();
	}

	/**
	 * Sets cover options. Accepts an object containing all or some of the cover
	 * params, which are an `imageUrl`, `link` and `url`. First two are
	 * obligarotry and must be provided if there is no config loaded from the
	 * Fusebox app.
	 *
	 * @param  cover Cover config object.
	 */
	setCover(cover: { [key: string]: any }) {
		if (!cover || 'object' !== typeof cover) {
			return;
		}

		let preparedCover: {
			imageUrl?: string;
			link?: boolean;
			url?: string;
		} = {};

		if ('boolean' === typeof cover.link) {
			preparedCover.link = cover.link;
		}

		if ('string' === typeof cover.url) {
			preparedCover.url = cover.url;
		}

		if ('string' === typeof cover.imageUrl) {
			preparedCover.imageUrl = cover.imageUrl;
		}

		preparedCover = {
			...this.config!.cover,
			...preparedCover,
		};

		const keys = Object.keys(preparedCover);

		if (!keys.includes('imageUrl') || !keys.includes('link')) {
			return;
		}

		this.config = {
			...this.config!,
			cover: preparedCover as Cover,
		};

		this.update();
	}

	/**
	 * Sets if the cover should be displayed.
	 *
	 * @param  display Whether to display the cover.
	 */
	setCoverDisplay(display: boolean) {
		this.config = {
			...this.config!,
			coverDisplay: !!display,
		};

		this.update();
	}

	/**
	 * Sets whether to display credits.
	 *
	 * @param  credits True if credits should be dipsplated, false otherwise.
	 */
	setCredits(credits: boolean) {
		this.config = {
			...this.config!,
			credits: !!credits,
		};

		this.update();
	}

	/**
	 * Sets CTA button config.
	 *
	 * @param  cta CTA config object or false to hide CTA.
	 */
	setCta(cta: false | Link) {
		if (
			false === cta &&
			this.config!.buttons &&
			this.config!.buttons.includes('cta')
		) {
			this.config = {
				...this.config!,
				buttons: this.config!.buttons.splice(
					this.config!.buttons.indexOf('cta'),
					1
				),
			};
		} else if (!cta || 'object' !== typeof cta) {
			return;
		}

		cta = pickByKey(cta as Object, ['label', 'url', 'target']) as Link;

		for (const key in cta) {
			if ('string' !== typeof cta[key as keyof Link]) {
				delete cta[key as keyof Link];
			}
		}

		this.config = {
			...this.config!,
			buttonConfig: {
				...this.config!.buttonConfig,
				cta: {
					...this.config!.buttonConfig?.cta,
					...cta,
				},
			},
		};

		this.update();
	}

	/**
	 * Sets current playing time.
	 *
	 * @param  time Time to set.
	 */
	setCurrentTime(time: number) {
		if (!this.audioComponent || typeof time !== 'number') {
			return;
		}

		time = Math.max(0, Math.min(this.audioComponent.duration, time));

		this.audioComponent.currentTime = time;
	}

	/**
	 * Mutes or unmutes the player.
	 *
	 * @param  muted Whether the player should be muted.
	 */
	setMuted(muted: boolean = true) {
		if (!this.audioComponent) {
			return;
		}

		this.audioComponent.muted = !!muted;
	}

	/**
	 * Sets playlist configuration.
	 *
	 * @param  playlist Playlist config object.
	 */
	setPlaylist(playlist: Playlist) {
		if (!this.config!.playlist && !this.isExtendedAPI()) {
			return;
		}

		const prevShouldUpdate = this.shouldUpdate;

		this.shouldUpdate = false;

		for (const key in playlist) {
			const method = `setPlaylist${
				key[0].toUpperCase() + key.substring(1)
			}`;

			if (typeof this[method as keyof PlayerAPI] === 'function') {
				(this[method as keyof PlayerAPI] as Function)(playlist[key]);
			}
		}

		this.shouldUpdate = prevShouldUpdate;
		this.update();
	}

	/**
	 * Sets playlist color.
	 *
	 * @param  color Playtlist color (`light` or `dark`).
	 */
	setPlaylistColor(color: 'light' | 'dark') {
		if (
			(!this.config!.playlist && !this.isExtendedAPI()) ||
			!['light', 'dark'].includes(color)
		) {
			return;
		}

		this.config = {
			...this.config!,
			playlist: {
				...this.config!.playlist!,
				color,
			},
		};

		this.update();
	}

	/**
	 * Sets whether to display Featured Episodes tab inside playlist.
	 *
	 * @param  display Whether to display Featured Episodes.
	 */
	setPlaylistDisplayFeaturedEpisodes(display: boolean) {
		if (!this.config!.playlist && !this.isExtendedAPI()) {
			return;
		}

		this.config = {
			...this.config!,
			playlist: {
				...this.config!.playlist!,
				displayFeaturedEpisodes: !!display,
			},
		};

		this.update();
	}

	/**
	 * Sets playlist features.
	 *
	 * @param  features Playlist features array (possible values are `searchbox`, `sort`, `popup`).
	 */
	setPlaylistFeatures(features: Array<PlaylistFeature>) {
		if (!this.config!.playlist && !this.isExtendedAPI()) {
			return;
		}

		this.config = {
			...this.config!,
			playlist: {
				...this.config!.playlist!,
				features: intersection(features, [
					'searchbox',
					'sort',
					'popup',
				]) as Array<PlaylistFeature>,
			},
		};

		this.update();
	}

	/**
	 * Sets playlist order.
	 *
	 * @param  order Playlist order (`asc` or `desc`)
	 */
	setPlaylistOrder(order: 'asc' | 'desc') {
		if (
			(!this.config!.playlist && !this.isExtendedAPI()) ||
			!['asc', 'desc'].includes(order)
		) {
			return;
		}

		this.config = {
			...this.config!,
			playlist: {
				...this.config!.playlist!,
				order,
			},
		};

		this.update();
	}

	/**
	 * Sets player position for sticky player.
	 *
	 * @param  position Top or bottom.
	 */
	setPosition(position: 'top' | 'bottom') {
		if (!['top', 'bottom'].includes(position)) {
			return;
		}

		this.config = {
			...this.config!,
			position,
		};

		this.update();
	}

	/**
	 * Sets player to sticky mode.
	 *
	 * @param  sticky Whether the player should be ticky.
	 */
	setSticky(sticky: boolean) {
		this.config = {
			...this.config!,
			sticky: !!sticky,
		};

		this.update();
	}

	/**
	 * Sets player style.
	 *
	 * @param  style Player style (`regular` or `large`).
	 */
	setStyle(style: string) {
		if (['regular', 'large'].includes(style)) {
			this.config = {
				...this.config!,
				style: style as 'regular' | 'large',
			};

			this.update();
		}
	}

	/**
	 * Sets subscribe links.
	 *
	 * @param  links Subscribe links array.
	 */
	setSubscribeLinks(links: { [key: string]: Link }) {
		if (!links || 'object' !== typeof links) {
			return;
		}

		const preparedLinks: { [key: string]: Link } = {};

		for (const key in links) {
			if (!links[key] || 'object' !== typeof links[key]) {
				continue;
			}

			const link = pickByKey(links[key], [
				'label',
				'url',
				'target',
			]) as Link;

			if (
				!link.label ||
				!link.url ||
				Object.values(link).some((x) => 'string' !== typeof x)
			) {
				continue;
			}

			preparedLinks[key] = link;
		}

		this.config = {
			...this.config!,
			buttonConfig: {
				...this.config!.buttonConfig,
				subscribe: preparedLinks,
			},
		};

		this.update();
	}

	/**
	 * Sets player volume.
	 *
	 * @param  volume Player volume.
	 */
	setVolume(volume: number) {
		if (!this.audioComponent || typeof volume !== 'number') {
			return;
		}

		volume = Math.max(0, Math.min(1, volume));

		this.audioComponent.volume = volume;
	}

	/**
	 * Updates player config.
	 */
	update(): void {
		if (this.shouldUpdate && isValidConfig(this.config!)) {
			this.setConfigCallback!(this.config);
			postMessage('config', this.config);
		}
	}
}
