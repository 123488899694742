export const availableCapabilities = ['basic', 'extended'] as const;

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
	infer ElementType
>
	? ElementType
	: never;

export type Capability = ElementType<typeof availableCapabilities>;
export type Capabilities = Array<Capability>;

export const isValidCapability = (value: string): value is Capability =>
	availableCapabilities.includes(value as Capability);
