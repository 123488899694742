/**
 * External dependencies
 */
import React, { FC, ReactNode, useState, useRef, useEffect } from 'react';
import Ticker from 'react-ticker';

/**
 * Internal dependencies
 */
import {
	withResizeObserver,
	withResizeObserverProps,
} from 'HOC/withResizeObserver';

type MovableTitleProps = {
	children?: ReactNode;
} & withResizeObserverProps;

const MovableTitle: FC<MovableTitleProps> = ({ children, width, setRef }) => {
	const [contentWidth, setContentWidth] = useState<number>(0);
	const [move, setMove] = useState<boolean>(true);
	const [movable, setMovable] = useState<boolean>(false);
	const [refresh, setRefresh] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);
	const ticker = useRef<Ticker>(null);

	useEffect(() => {
		ref.current && setRef(ref);
	}, [ref, setRef]);

	useEffect(() => {
		setMovable(false);
		setRefresh(true);
	}, [children]);

	useEffect(() => {
		if (true === refresh && ref.current) {
			setContentWidth(ref.current.scrollWidth);
			setRefresh(false);
		}
	}, [refresh]);

	useEffect(() => {
		if (ref.current && contentWidth) {
			setMovable(ref.current.offsetWidth < contentWidth);
		}
	}, [ref, width, contentWidth]);

	useEffect(() => {
		if (ticker.current && ticker.current.onResize) {
			ticker.current.onResize();
		}
	}, [width]);

	useEffect(() => {
		if (ref.current) {
			const element = ref.current;
			const start = () => setMove(true);
			const stop = () => setMove(false);

			element.addEventListener('mouseenter', stop);
			element.addEventListener('mouseleave', start);

			return () => {
				element.removeEventListener('mouseenter', stop);
				element.removeEventListener('mouseleave', start);
			};
		}
	}, [ref]);

	return (
		<div ref={ref} className='fbx-movable-title'>
			{movable ? (
				<Ticker speed={3} move={move} ref={ticker}>
					{() => <p className='fbx-movable'>{children}</p>}
				</Ticker>
			) : (
				<p className='fbx-static'>{children}</p>
			)}
		</div>
	);
};

export default withResizeObserver(MovableTitle);
