/**
 * External dependencies
 */
import React, { FC, useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { findIndex } from 'lodash';

/**
 * Internal dependencies
 */
import { PlayIcon, PauseIcon, ChevronRightIcon } from 'icons';
import { withAudioUtils, withAudioUtilsProps } from 'HOC/withAudioUtils';
import { withPlayerState, withPlayerStateProps } from 'HOC/withPlayerState';
import { useConfig, useSetConfig } from 'context/config';
import { formatTime, scrollParentToChild, loadDescription } from 'utils';
import Button from 'components/Button';
import type { Track } from 'types';

type PlaylistItemProps = {
	onToggle?: (expanded: boolean) => void;
	track: Track;
} & withPlayerStateProps &
	withAudioUtilsProps;

const PlaylistItem: FC<PlaylistItemProps> = ({
	audioComponent,
	onToggle,
	playerState,
	setPlayerState,
	track,
}): JSX.Element => {
	const { description, duration, episodeNo, title } = track;

	const descriptionRef = useRef<HTMLDivElement>(null);
	const itemRef = useRef<HTMLDivElement>(null);
	const [expanded, setExpanded] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(!!description);
	const [descriptionHeight, setDescriptionHeight] = useState<number>(0);

	useEffect(() => {
		if (descriptionRef.current) {
			const descriptionElement = descriptionRef.current;

			if (onToggle) {
				const onTransitionEnd = () => onToggle(expanded);

				descriptionElement.addEventListener(
					'transitionend',
					onTransitionEnd
				);

				return () =>
					descriptionElement.removeEventListener(
						'transitionend',
						onTransitionEnd
					);
			}
		}
	}, [descriptionRef, onToggle, expanded]);

	useEffect(() => {
		if (descriptionRef.current) {
			setDescriptionHeight(descriptionRef.current.scrollHeight);
		}
	}, [description, descriptionRef]);

	const { currentEpisode, isPlaying } = playerState;

	const isCurrent = currentEpisode && currentEpisode.episodeNo === episodeNo;

	useEffect(() => {
		if (isCurrent) {
			setTimeout(() => {
				if (itemRef.current) {
					scrollParentToChild(
						itemRef.current.parentNode!.parentNode as HTMLElement,
						itemRef.current
					);
				}
			}, 0);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const config = useConfig();
	const setConfig = useSetConfig();

	const expand = async () => {
		setExpanded(!expanded);

		if (!loaded) {
			setLoaded(true);

			try {
				const description = episodeNo
					? await loadDescription(config!.showHash, episodeNo)
					: '';

				const { tracks, ...otherConfig } = config!;
				const index = findIndex(
					tracks,
					(t) => t.episodeNo === episodeNo
				);

				tracks.splice(index, 1, {
					...track,
					description,
				});

				setConfig!({
					...otherConfig,
					tracks,
				});
			} catch (e) {}
		}
	};

	const handlePlayPauseClick = () => {
		if (isCurrent) {
			if (isPlaying) {
				audioComponent.pause();
			} else {
				audioComponent.play();
			}
		} else {
			setPlayerState({
				...playerState,
				currentEpisode: track,
			});
		}
	};

	const Icon = isCurrent && isPlaying ? PauseIcon : PlayIcon;

	const className = classnames('fbx-playlist-item', {
		'fbx-is-current': isCurrent,
		'fbx-is-expanded': expanded,
	});

	const descriptionMaxHeight = expanded ? descriptionHeight : 0;

	const buttonLabel = expanded ? 'hide info' : 'more info';

	return (
		<div className={className} ref={itemRef}>
			<div className='fbx-playlist-item-header'>
				<Button
					className='fbx-play-pause-playlist-button'
					buttonStyle="none"
					onClick={handlePlayPauseClick}
					icon={<Icon />}
				/>
				<div className='fbx-episode-info'>
					<p className='fbx-episode-title'>
						<span dangerouslySetInnerHTML={{ __html: title }} />
						<button className='fbx-more-info' onClick={expand}>
							{buttonLabel}
							<ChevronRightIcon />
						</button>
					</p>
					<div
						className='fbx-episode-description'
						ref={descriptionRef}
						style={{ height: descriptionMaxHeight }}
					>
						{description ? (
							<p
								dangerouslySetInnerHTML={{
									__html: description,
								}}
							/>
						) : (
							<p>Loading...</p>
						)}
					</div>
				</div>
				{duration ? (
					<div className='fbx-episode-duration'>
						{formatTime(duration)}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default withAudioUtils(withPlayerState(PlaylistItem));
