/**
 * External dependencies
 */
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import { Button } from 'components';
import { ArrowToBottomIcon } from 'icons';
import { useCurrentEpisode } from 'hooks';

type DownloadButtonProps = {
	hasLabel?: boolean;
};

const DownloadButton: FC<DownloadButtonProps> = ({ hasLabel }) => {
	const currentEpisode = useCurrentEpisode();

	const url = `https://dl.fusebox.fm/download/?url=${currentEpisode?.file}`;

	return (
		<Button icon={<ArrowToBottomIcon />} buttonStyle="secondary" url={url}>
			{hasLabel && 'Download'}
		</Button>
	);
};

export default DownloadButton;
