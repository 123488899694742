/**
 * External dependencies
 */
import classnames from 'classnames';
import React, { FC, useRef, useEffect } from 'react';
import Clamp from 'react-multi-clamp';

/**
 * Internal dependencies
 */
import { MovableTitle } from 'components';

type PodcastTitleProps = {
	singleLine?: boolean;
	showTitle?: string;
	title: string;
	align?: 'left' | 'center';
	clamp?: number;
};

const PodcastTitle: FC<PodcastTitleProps> = ({
	align,
	clamp,
	showTitle,
	singleLine,
	title,
}) => {
	const multiclamp = useRef<Clamp>(null);

	useEffect(() => {
		if (multiclamp.current && multiclamp.current.multiClamp) {
			multiclamp.current.multiClamp.reload({ clamp });
		}
	}, [clamp]);

	const alignClass = `align-${align}`;

	const classNames = classnames(alignClass, { 'fbx-is-single-line': singleLine });

	const showTitleClassName = classnames('fbx-show-title', classNames);
	const episodeTitleClassName = classnames('fbx-episode-title', classNames);

	return (
		<>
			{showTitle && (
				<div className={showTitleClassName}>
					{singleLine ? (
						<MovableTitle>{showTitle}</MovableTitle>
					) : (
						<p>{showTitle}</p>
					)}
				</div>
			)}
			<div className={episodeTitleClassName}>
				{singleLine ? (
					<MovableTitle>{title}</MovableTitle>
				) : (
					<Clamp clamp={clamp} ref={multiclamp}>
						{title}
					</Clamp>
				)}
			</div>
		</>
	);
};

PodcastTitle.defaultProps = {
	singleLine: true,
	align: 'left',
	clamp: 3,
};

export default PodcastTitle;
