/**
 * External dependencies
 */
import React, {
	Children,
	cloneElement,
	FC,
	isValidElement,
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { usePopper } from 'react-popper';
import type { Placement } from '@popperjs/core/lib';

/**
 * Internal dependencies
 */
import type { ButtonProps } from 'components/Button';
import {
	withConfig,
	withConfigProps,
	withPlayerState,
	withPlayerStateProps,
} from 'HOC';
import { PopoverArrowIcon } from 'icons';

export type PopoverOpenEvent = 'click' | 'hover';
export type PopoverLocation = 'top' | 'bottom';

type PopoverProps = {
	children?: ReactElement<ButtonProps>;
	className?: string;
	content: ReactNode;
	location?: PopoverLocation;
	openEvent?: PopoverOpenEvent;
	placement?: Placement;
	refCallback?: (ref: HTMLDivElement) => void;
} & withPlayerStateProps &
	withConfigProps;

type PopoverPortalProps = {
	children?: ReactNode;
} & withConfigProps;

let popoversWrapper: HTMLDivElement;

const PopoverPortal: FC<PopoverPortalProps> = ({
	children,
	config: { id },
}) => {
	if (!popoversWrapper) {
		popoversWrapper = document.createElement('div');
		popoversWrapper.setAttribute('id', `fusebox-popovers-${id}`);
		document.body.appendChild(popoversWrapper);
	}

	return createPortal(children, popoversWrapper);
};

const PopoverPortalWithConfig = withConfig(PopoverPortal);

const Popover: FC<PopoverProps> = ({
	children,
	className: userClassName,
	config: { color },
	content,
	location,
	openEvent,
	placement,
	playerState: { theme, disabled },
	refCallback,
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null);
	const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
	const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

	const { styles, attributes, update } = usePopper(buttonRef, popoverRef, {
		placement: placement,
		modifiers: [
			{
				name: 'arrow',
				options: { element: arrowRef },
			},
		],
	});

	const togglePopover = () => {
		setOpen(!open);
		update && update();
	};

	useEffect(() => {
		if (disabled && open) {
			setOpen(false);
		}
	}, [disabled, open]);

	useEffect(() => {
		popoverRef && refCallback && refCallback(popoverRef);
	}, [popoverRef, refCallback]);

	useEffect(() => {
		popoverRef && popoverRef.style.setProperty('--fusebox-accent', color);
	}, [color, popoverRef]);

	useEffect(() => {
		if ('click' === openEvent) {
			const handleDocumentClick: (e: MouseEvent) => void = (e) => {
				if (!open || !popoverRef || !buttonRef) {
					return;
				}

				const target = e.target as HTMLElement;

				if (
					!target.isSameNode(popoverRef) &&
					!target.isSameNode(buttonRef) &&
					!buttonRef.contains(target) &&
					!popoverRef.contains(target)
				) {
					togglePopover();
				}
			};

			document.documentElement.addEventListener(
				'click',
				handleDocumentClick
			);

			return () =>
				document.documentElement.removeEventListener(
					'click',
					handleDocumentClick
				);
		} else if (buttonRef) {
			const buttonElement = buttonRef;
			const handleMouseEnter = () => !open && togglePopover();

			buttonElement.addEventListener('mouseenter', handleMouseEnter);
			buttonElement.addEventListener('mouseleave', togglePopover);

			return () => {
				buttonElement.removeEventListener(
					'mouseenter',
					handleMouseEnter
				);
				buttonElement.removeEventListener('mouseleave', togglePopover);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buttonRef, open, openEvent, popoverRef]);

	const clonedChildren = Children.map(
		children,
		(element) =>
			isValidElement(element) &&
			cloneElement(element, {
				...element.props,
				ref: setButtonRef,
				onClick: () => {
					'click' === openEvent && togglePopover();
					element.props.onClick();
				},
			})
	);

	const className = classnames(
		userClassName,
		'fbx-popover',
		`fbx-has-${theme}-theme`,
		`fbx-to-${location}`,
		{
			'fbx-is-open': open,
		}
	);

	return (
		<>
			{clonedChildren}
			<PopoverPortalWithConfig>
				<div
					className={className}
					style={styles.popper}
					ref={setPopoverRef}
					{...attributes.popper}
				>
					<div
						className='fbx-arrow'
						ref={setArrowRef}
						style={styles.arrow}
					>
						<PopoverArrowIcon />
					</div>
					<div className='fbx-popover-content'>{content}</div>
				</div>
			</PopoverPortalWithConfig>
		</>
	);
};

Popover.defaultProps = {
	openEvent: 'click',
	location: 'bottom',
};

export default withPlayerState(withConfig(Popover));
