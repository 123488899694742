/**
 * External dependencies
 */
import type { IObjectKeys } from 'interfaces';
import { pickBy } from 'lodash';

export const intersect = (
	a: Array<string>,
	b: Array<string>
): Array<string> => {
	const set = new Set(b);
	return a.filter((item) => set.has(item));
};

export const pickByKey = (
	object: IObjectKeys,
	allowedKeys: Array<string>
): Object => pickBy(object, (value, key) => allowedKeys.includes(key));
