/**
 * External dependencies
 */
import { omit } from 'lodash';
import classnames from 'classnames';
import React, {
	FC,
	forwardRef,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import Scrollbars from 'react-custom-scrollbars';

/**
 * Internal dependencies
 */
import { useCombinedRefs } from 'hooks';

type ScrollableProps = {
	children?: ((update: () => void) => ReactNode) | ReactNode;
	className?: string;
};

const Scrollable: FC<ScrollableProps> = forwardRef<
	HTMLDivElement,
	ScrollableProps
>(({ children, className }, forwardedRef) => {
	const wrapRef = useCombinedRefs(forwardedRef);
	const contentRef = useRef<HTMLDivElement>(null);

	const [contentHeight, setContentHeight] = useState<number>(0);
	const [hasScrollbars, setHasScrollbars] = useState<boolean>(false);

	const update = () => {
		if (
			contentRef.current &&
			contentHeight !== contentRef.current.clientHeight
		) {
			setContentHeight(contentRef.current.clientHeight);
		}
	};

	useEffect(update);

	useEffect(() => {
		if (wrapRef.current) {
			const style = window.getComputedStyle(wrapRef.current);
			const height =
				wrapRef.current.clientHeight -
				parseFloat(style.paddingTop) -
				parseFloat(style.paddingBottom);

			setHasScrollbars(contentHeight > height);
		}
	}, [contentHeight, wrapRef]);

	const filterProps = (props: {
		[key: string]: any;
		style?: Object;
	}): Object => ({
		...props,
		style: props.style ? omit(props.style, ['width', 'height']) : null,
	});

	return (
		<div
			className={classnames(className, 'fbx-scrollable', {
				'fbx-has-scrollbars': hasScrollbars,
			})}
			ref={wrapRef}
			style={{ height: `${contentHeight}px` }}
		>
			<Scrollbars
				hideTracksWhenNotNeeded={true}
				renderTrackHorizontal={(props) => (
					<div
						{...filterProps(props)}
						className='fbx-track fbx-track-horizontal'
					/>
				)}
				renderTrackVertical={(props) => (
					<div
						{...filterProps(props)}
						className='fbx-track fbx-track-vertical'
					/>
				)}
				renderThumbHorizontal={(props) => (
					<div {...props} className='fbx-thumb fbx-thumb-horizontal' />
				)}
				renderThumbVertical={(props) => (
					<div {...props} className='fbx-thumb fbx-thumb-vertical' />
				)}
			>
				<div className='fbx-scrollable-content' ref={contentRef}>
					{children && typeof children === 'function'
						? (children as any)(update)
						: children}
				</div>
			</Scrollbars>
		</div>
	);
});

export default Scrollable;
